import { useState, useEffect } from "react";
import {useParams} from 'react-router-dom';
import { get } from 'aws-amplify/api';
import ProgressBar from "./ProgressBar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faHandPaper, faKey, faOctagon } from "@fortawesome/pro-light-svg-icons";
import axios from "axios";
import { Spinner } from "./Spinner";

import API from '../services/Api.js';

function GetFile(props) {
    const {pathToken} = useParams();

    const [downloadDidStart, setDownloadDidStart] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [statusMessage, setStatusMessage] = useState('');
    const [percentCompleted, setPercentCompleted] = useState(0);
    const [waiting, setWaiting] = useState(false);
    const [token, setToken] = useState(pathToken);
    const [veriCode, setVeriCode] = useState(null);
    const [verificationRequired, setVerificationRequired] = useState(false);
    const [showDownloadCompleteModal, setShowDownloadCompleteModal] = useState(false);

    useEffect(() => {
        console.log('useEffect:', pathToken, downloadDidStart);
        if (token && !downloadDidStart) {
            download();
        }
    }, [token]);

    async function resendAccessCode() {
        console.log('resendAccessCode:: START');
        setDownloadDidStart(true);
        setWaiting(true);
        setShowDownloadCompleteModal(false);

        try {
            await get('giveafileApi', `/token/${token}`, {
                queryStringParameters: {
                    sendAccessCode: true,
                }
            });
        } catch (error) {
            console.error(error);
            const status = (error.response && error.response.status) || error;
            setStatusMessage('');
            if (status === 401) {
                setVerificationRequired(true);
            } else if (status === 404) {
                setErrorMessage('Oops, looks like the token is not valid. Ask the sender for a correct token.');
            } else {
                setErrorMessage(`An unexpected error occurred (Status ${status}). Please try again.`);
            }
        } finally {
            setDownloadDidStart(false);
            setWaiting(false);
        }
    }

    function reset() {
        setVerificationRequired(false);
        setToken('');
        setVeriCode('');
        setErrorMessage('');
        setStatusMessage('');
        setShowDownloadCompleteModal(false);
    }

    async function download() {
        try {
            setDownloadDidStart(true);
            setWaiting(true);
            setShowDownloadCompleteModal(false);

            // const response = await API.get('GetFileAPI', `/file/${token}`, {
            // const response = await get('giveafileApi', `/token/${token}`, {
            //     queryStringParameters: {
            //         accessCode: veriCode
            //     }
            // });

            const response = await API.getFileGive(token, veriCode);
            console.log('Get Response:', response);
            const downloadRequestStatus = response.status;
            if(downloadRequestStatus === 'OK') {
                const presignURL = response.presignUrl;
                // const presignURL = decodeURI(response.presignUrl);
                const fileName = response.fileName;
                console.log('presignURL', presignURL)
                if (presignURL) {
                    const fileResponse = await axios.get(presignURL, {
                        responseType: 'blob',
                        onDownloadProgress: progressEvent => {
                            console.log('ProgressEvent', progressEvent);
                            if (isNaN(progressEvent.total)) { return }
                            setPercentCompleted(Math.round((progressEvent.progress) * 100.0));
                        }
                    });
                    console.log('fileResponse', fileResponse);
                    const href = URL.createObjectURL(fileResponse.data);

                    setPercentCompleted(0.0);
                    let filename = fileName;
                    const contentDisposition = response.ContentDisposition;
                    console.log('contentDisposition', contentDisposition);
                    //response.headers['content-disposition']
                    if (contentDisposition) {
                        //const contentDisposition = response.headers['content-disposition'].split(';');
                        if (contentDisposition.indexOf('attachment') !== -1) {
                            const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                            const matches = filenameRegex.exec(contentDisposition);
                            if (matches != null && matches[1]) {
                                filename = matches[1].replace(/['"]/g, '');
                            }
                        }
                    }

                    // create "a" HTML element with href to file & click
                    const link = document.createElement('a');
                    link.href = href;
                    link.setAttribute('download', filename);
                    document.body.appendChild(link);
                    link.click();

                    document.body.removeChild(link);
                    URL.revokeObjectURL(href);

                    setVerificationRequired(false);
                    setToken('');
                    setVeriCode('');
                    setErrorMessage('');
                    setStatusMessage('Your download is now complete. Thank you for using giveafile.com!');
                    setShowDownloadCompleteModal(true);
                }
            } else if (downloadRequestStatus === 'CODE_MISMATCH'){ 
                setErrorMessage('Incorrect verification code. Please check your email for the verification code');
                setStatusMessage('');
                setVerificationRequired(true);
                setVeriCode('');
            } else if (downloadRequestStatus === 'EXIST_VERICODE' ) {
                setErrorMessage('Please enter the verification code that was previously sent to your email. You can always request a new verification code.');
                setStatusMessage('');
                setVerificationRequired(true);
                setVeriCode('');
            } else if(downloadRequestStatus === 'NEW_VERICODE') {
                setErrorMessage('');
                setStatusMessage('');
                setVerificationRequired(true);
                setVeriCode('');
            } else /*if(downloadRequestStatus === 'OK')*/ {
                setErrorMessage('');
                setStatusMessage('');
                setVerificationRequired(true);
                setVeriCode('');
            }
        } catch (error) {
            const status = (error.response && error.response.statusCode) || error;
            setStatusMessage('');
            if (status === 401) {
                setVerificationRequired(true);
                // setStatusMessage('An access code has been sent to the email of the intended recipient. Please check your email and enter the access code below.');
            } else if (status === 404) {
                setErrorMessage('Oops, looks like the token is not valid. Ask the sender for a correct token.');
            } else {
                setErrorMessage(`An unexpected error occurred (Status ${status}). Please try again.`);
            }
        } finally {
            setDownloadDidStart(false);
            setWaiting(false);
        }
        
    }

//     async function download1() {
//         try {
//             setDownloadDidStart(true);
//             setWaiting(true);
//             setShowDownloadCompleteModal(false);

//             const url = API_BASE_URL + encodeURIComponent(token);
//             const resp = await axios.get(url, {
//                 params: {accessCode: veriCode}
//             });

//             const downloadRequestStatus = resp.data.Status;
//             if(downloadRequestStatus === 'OK') {
//                 const presignURL = resp.data.PresignUrl;
//                 if (presignURL) {
//                     const response = await axios.get(presignURL, {
//                         responseType: 'blob',
//                         onDownloadProgress: progressEvent => {
//                             console.log('ProgressEvent', progressEvent);
//                             if (isNaN(progressEvent.total)) { return }
//                             setPercentCompleted(Math.round((progressEvent.progress) * 100.0));
//                         }
//                     });
//                     const href = URL.createObjectURL(response.data);

//                     setPercentCompleted(0.0);
//                     let filename = 'giveafile-download';
//                     const contentDisposition = resp.data.ContentDisposition;
//                     //response.headers['content-disposition']
//                     if (contentDisposition) {
//                         //const contentDisposition = response.headers['content-disposition'].split(';');
//                         if (contentDisposition.indexOf('attachment') !== -1) {
//                             const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
//                             const matches = filenameRegex.exec(contentDisposition);
//                             if (matches != null && matches[1]) {
//                                 filename = matches[1].replace(/['"]/g, '');
//                             }
//                         }
//                     }

//                     // create "a" HTML element with href to file & click
//                     const link = document.createElement('a');
//                     link.href = href;
//                     link.setAttribute('download', filename);
//                     document.body.appendChild(link);
//                     link.click();

//                     document.body.removeChild(link);
//                     URL.revokeObjectURL(href);

//                     setVerificationRequired(false);
//                     setToken('');
//                     setVeriCode('');
//                     setErrorMessage('');
//                     setStatusMessage('Your download is now complete. Thank you for using giveafile.com!');
//                     setShowDownloadCompleteModal(true);
//                 }
//             } else if (downloadRequestStatus === 'CODE_MISMATCH'){ 
//                 setErrorMessage('Incorrect verification code. Please check your email for the verification code');
//                 setStatusMessage('');
//                 setVerificationRequired(true);
//                 setVeriCode('');
//             } else /*if(downloadRequestStatus === 'OK')*/ {
//                 setErrorMessage('');
//                 setStatusMessage('');
//                 setVerificationRequired(true);
//                 setVeriCode('');
//             }

//             /*
//             const response = await axios.get(`https:////api.giveafile.com/files?token=${encodeURIComponent(token)}`, {
//                 responseType: 'blob',
//                 onDownloadProgress: progressEvent => {
//                     setPercentCompleted((progressEvent.loaded / progressEvent.total) * 100.0);
//                 }
//             });
//             const href = URL.createObjectURL(response.data);
//             let filename = 'download';
//             const contentDisposition = response.headers['content-disposition'].split(';');
//             if (contentDisposition && contentDisposition.indexOf('attachment') !== -1) {
//                 const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
//                 const matches = filenameRegex.exec(contentDisposition);
//                 if (matches != null && matches[1]) {
//                     filename = matches[1].replace(/['"]/g, '');
//                 }
//             }

//             // create "a" HTML element with href to file & click
//             const link = document.createElement('a');
//             link.href = href;
//             link.setAttribute('download', filename);
//             document.body.appendChild(link);
//             link.click();

//             setToken('');
//             setErrorMessage('');
//             setStatusMessage('Your download is now complete. Thank you for using giveafile.com!');
// */
//             // clean up "a" element & remove ObjectURL
//             //document.body.removeChild(link);
//             //URL.revokeObjectURL(href);
//         } catch (error) {
//             const status = (error.response && error.response.status) || error;
//             setStatusMessage('');
//             if (status === 404) {
//                 setErrorMessage('Oops, looks like the token is not valid. Ask the sender for a correct token.');
//             } else {
//                 setErrorMessage(`An unexpected error occurred (Status ${status}). Please try again.`);
//             }
//         } finally {
//             setDownloadDidStart(false);
//             setWaiting(false);
//         }
//     }

    function DownloadCompleteModal() {
        return (
            <>
                <div
                  className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
                >
                    <div className="relative w-auto my-6 mx-auto max-w-3xl">
                        {/*content*/}
                        <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full p-5 bg-white outline-none focus:outline-none">
                            {/*header*/}
                            <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                                <button
                                  className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                  onClick={() => setShowDownloadCompleteModal(false)}
                                >
                                    <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                                        ×
                                    </span>
                                </button>
                            </div>
                            {/*body*/}
                            {downloadDidStart && <ProgressBar percentCompleted={percentCompleted}></ProgressBar>}
                            <div className="relative p-6 flex-auto">
                                <p className="my-4 text-slate-500 text-lg leading-relaxed text-center">
                                    <FontAwesomeIcon icon={faCheckCircle} size="3x" className="text-green-500 pb-5" />
                                    <br />
                                    {statusMessage}
                                </p>
                            </div>
                            {/*footer*/}
                            <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                                <button
                                    className="text-blue-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                    type="button"
                                    onClick={reset}
                                >
                                    Get another
                                </button>
                                <button
                                  // className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                  className="primary"
                                  type="button"
                                  onClick={reset}
                                >
                                    OK, Thanks
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
            </>
        );
    }

    return(
        <>
        {waiting && <Spinner/>}
        <div className="flex flex-col items-center">
            {!verificationRequired &&
                <>
                <div className="text-center pb-4">
                    <p>
                        Have a token? Enter it below to get your file.
                    </p>
                </div>
                <div>
                    <form>
                        <fieldset>
                            <div className="text-center">
                                <div id="input-control">
                                    <label className="relative text-gray-400 focus-within:text-gray-600 block">
                                        <FontAwesomeIcon icon={faKey} className="text-md absolute top-1/2 transform -translate-y-1/2 left-3" />
                                        <span className="sr-only">File Access Token</span>
                                        <input type="text" name="token" value={token} onInput={e => setToken(e.target.value)}
                                                disabled={downloadDidStart} placeholder="Access token" size="40" className="pl-8" />
                                    </label>
                                    <div className="help"><span></span><figure className="is-inline"></figure></div>
                                </div>
                                <div>
                                    <button type="button" onClick={download} disabled={downloadDidStart} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded m-5">Get file</button>
                                </div>
                            </div>
                        </fieldset>
                    </form>
                </div>
                </>
            }
            {verificationRequired &&
                <div className="flex flex-col items-center mt-5">
                        <p className="mb-5 text-center">An access code has been sent to the email of the intended recipient of this file.<br/>
                            Please enter it below to get your file</p>
                        <label htmlFor="veriCode" className="sr-only" >Access Code</label>
                        <input type="text" name="veriCode" id="veriCodeInput"
                            placeholder="Verification Code"
                            value={veriCode}
                            onInput={e => setVeriCode(e.target.value)}
                            className="appearance-none block text-gray-700 border border-red-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                        />
                        <button type="button" onClick={download} disabled={downloadDidStart} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded m-5">Get file</button>
                        <button type="button" onClick={resendAccessCode} disabled={downloadDidStart}
                            className="text-gray-400 background-transparent font-bold uppercase px-3 py-1 text-xs outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150">
                            Resend access code
                        </button>
                </div>
            }
            {errorMessage &&
                <div className="text-red-500">
                    <span className="fa-stack fa-2x">
                        <FontAwesomeIcon icon={faOctagon} className="fa-stack-2x" />
                        <FontAwesomeIcon icon={faHandPaper} className="fa-stack-1x" />
                    </span>
                    {errorMessage}
                </div>
            }
            {showDownloadCompleteModal ? <DownloadCompleteModal /> : null}
            {/*statusMessage &&
                <div className="text-green-500">
                    <FontAwesomeIcon icon={faCheck} size="2x" />
                    <i className="fal fa-2x fa-check-square"></i>
                    {statusMessage}
                </div>
            */}
        </div>
        </>
    );
}

export default GetFile;
