export default function TermsAndConditions() {
    return (
        <div className="flex flex-col gap-4 px-6">
            <h1 className="text-zinc-800 text-[40px] font-['Rethink Sans'] leading-[48px] pb-10">
                Terms and conditions
            </h1>
            <article>
                <div className="text-zinc-500 text-base font-['Geist Variable'] leading-normal flex flex-col gap-4">
                    Give a File is a service provided by Work Smarter Computing LLC for sending and receiving files. Neither giveafile.com nor Work Smarter Computing are responsible for the contents of files and no guarantee of any kind is given as to the functionality or usage of this service for any purpose.
                </div>
            </article>

            <article className="flex flex-col gap-5 leading-normal">
                <h1 className="text-zinc-800 text-[32px] font-['Rethink Sans'] leading-10">
                    Using giveafile.com
                </h1>
                <p className="text-zinc-500 text-base font-['Geist Variable'] leading-normal">
                    By using this service, you acknowledge acceptance of these terms and confirm that you are using this service at your own risk with no guarantees what-so-ever from Work Smarter Computing or Give a File. All that Give a File does is store files on a temporary basis and provides a mean to access these files to anyone with procession of the access URL.
                </p>
            </article>
        </div>
    );
}
