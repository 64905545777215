export function GAFButton(props) {
    const buttonType = props.type;

        /* <div className={`px-5 py-2 ${(!buttonType || buttonType==='primary') && "bg-indigo-500 rounded-xl"} shadow-inner justify-center items-center inline-flex`}> */
        /*     {/* <button className={`${(!buttonType || buttonType==='primary') && "text-white font-semibold"} text-sm leading-normal`} */
        /*     <button className="primary" */
        /*         onClick={props.onClick} */
        /*     >{props.children}</button> */
        /* </div> */
    return (
        <button className={`${(buttonType==='primary') ? "primary": ""}`}
            onClick={props.onClick}
        >{props.children}</button>
    );
}

