import { get, post } from 'aws-amplify/api';

function getApiName(user) {
    let apiName;

    if (user) {
        apiName = 'giveafileApi';
    } else {
        apiName = 'giveafilePublicApi';
    }

    return apiName;
}

async function getConstraints(user) {
    const apiName = getApiName(user);
    const op = get({
        apiName: apiName,
        path: '/constraints',
    });

    const response = await op.response;
    const constraints = await response.body.json();

    return constraints;
}

async function getFileGive(token, accesscode) {
    const apiName = getApiName();
    const op = get({
        apiName: apiName,
        path: `/${token}`,
        options: {
            queryParams: {
                accessCode: accesscode || '',
            }
        },
    });

    const response = await op.response;
    const json = await response.body.json();

    return json;
}

const API = {
    getFileGive,
    getConstraints,
};

export default API;

