import { Link } from "react-router-dom";

export function Footer() {
    return (
        <footer className="w-full p-5">
            <div className="w-full h-[52px] py-4 rounded-[28px] justify-between items-center inline-flex">
                <div className="text-zinc-500 text-sm font-['Geist Variable'] leading-tight">All rights reserved © 2024, Work Smarter Computing LLC.</div>
                <div className="justify-center items-start gap-8 flex">
                    <Link to="/help"
                        className="text-zinc-500 text-sm font-['Geist Variable'] leading-tight border-b border-0 border-zinc-400"
                    >About</Link>
                    <Link to="/terms"
                        className="text-zinc-500 text-sm font-['Geist Variable'] leading-tight border-b border-0 border-zinc-400"
                    >Terms and conditions</Link>
                </div>
            </div>
        </footer>
    );
}
