import { useState, useEffect } from 'react';
import { get, post } from 'aws-amplify/api';
import { getCurrentUser, fetchAuthSession } from 'aws-amplify/auth';
import Confirmation from './Confirmation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle, faFile, faFileCode, faFilePdf, faFileZip, faQuestionCircle } from '@fortawesome/pro-light-svg-icons';
import { Spinner } from './Spinner';
import { GAFButton } from './GAFButton';
import prettyBytes from 'pretty-bytes';
import { GAFToggle } from './GAFToggle';
import Tooltip from './Tooltip';

// const { useState } = require('react');
const { DurationBox } = require('./DurationBox');

function getApiName(user) {
    let apiName;

    if (user) {
        apiName = 'giveafileApi';
    } else {
        apiName = 'giveafilePublicApi';
    }

    return apiName;
}

function AdditionalOptions(props) {
    const [showZipPasswordBox, setShowZipPasswordBox] = useState(false);
    const [showRecipientsBox, setShowRecipientsBox] = useState(false);

    const [recipients, setRecipients] = useState(props.recipients);
    const [zipPassword, setZipPassword] =  useState('');

    const model = {
        zipIt: showZipPasswordBox,
        zipPassword: zipPassword,
        notifyRecipient: showRecipientsBox,
        recipients: recipients,
    };

    const onZipPasswordChange = (e) => {
        setZipPassword(e.target.value);
        model['zipPassword'] = e.target.value;
        notifyCaller();
    }

    const onRecipientsChange = (e) => {
        const inputEmail = e.target.value;
        setRecipients(inputEmail);
        model['recipients'] = inputEmail;
        notifyCaller();
    };

    const notifyCaller = () => {
        if (props.onChange && typeof(props.onChange) === 'function') {
            props.onChange(model);
        }
    }

    return (
        <div className="px-5 py-4 bg-white rounded-xl border border-gray-200 flex-col justify-start items-start gap-4 flex grow shrink">
            <div className="self-stretch flex-col justify-start items-start gap-4 flex">
                <div className="w-full items-center gap-2 grid grid-cols-2">
                    <GAFToggle
                        active={showRecipientsBox}
                        onClick={(isActive) => {
                            setShowRecipientsBox(isActive);
                            model['notifyRecipient'] = isActive;
                            notifyCaller();
                        }}
                    >
                        Notify recipient
                        <div className="group relative inline-flex">
                            <FontAwesomeIcon icon={faQuestionCircle} className="ms-1"/>
                            <Tooltip>Automatically send an email to the recipient notifying them that a file is waiting.</Tooltip>
                        </div>
                    </GAFToggle>
                    <input type="email"
                        key="recipientsText"
                        placeholder="Enter recipients' email address."
                        value={recipients}
                        onChange={onRecipientsChange}
                        disabled={!showRecipientsBox}
                        size="32"
                    />
                </div>
            </div>
        </div>
    );
}

function FileUploadForm(props) {
    const [expiresIn, setExpiresIn] = useState(3600);
    const [showRecipientsBox, setShowRecipientsBox] = useState(false);
    const [recipients, setRecipients] = useState();
    const [filelist, setFilelist] = useState([]);
    const [filePreview, setFilePreview] = useState('');

    const [isDragActive, setIsDragActive] = useState(false);

    const [uploadError, setUploadError] = useState('');

    const maxFileSize = props.maxFileSize || 0;
    const user = props.user;
    const userSession = props.userSession;
    const showAdditionalOptions = user !== undefined && user != null;

    const fileTypeIconMap = {
        'application/pdf': faFilePdf,
        'application/json': faFileCode,
        'application/zip': faFileZip,
    };

    const onDrop = (event) => {
        event.preventDefault();
        event.stopPropagation();

        const droppedFiles = event.dataTransfer.files;
        setFilelist([...droppedFiles]);

        setIsDragActive(false);
        if (droppedFiles.length > 0) {
            const firstFile = droppedFiles[0];
            setFilePreview(URL.createObjectURL(firstFile))
        }
    }

    const onDrag = (event) => {
        event.preventDefault();
        event.stopPropagation();

        if (event.type === "dragenter" || event.type === "dragover") {
            setIsDragActive(true);
        } else if (event.type === "dragleave") {
            setIsDragActive(false);
        }
    }

    const error = (errorMessage) => {
        setUploadError(errorMessage);
        props.onUploadComplete && props.onUploadComplete({
            error: errorMessage
        });
    }

    const upload = async (event) => {
        event.preventDefault();

        setUploadError('');
        props.onUploadStart && props.onUploadStart();

        if (filelist.length == 0) {
            error('No files selected to upload');
            return;
        }

        const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        if (recipients && !recipients.match(emailRegex)) {
            error("Recipient's email is not a valid email address.");
            return;
        }

        try {
            // const form = event.target;
            // const formData = new FormData(form);
            // const formJson = Object.fromEntries(formData.entries());
            const file = filelist[0];
            // const uploadForm = new FormData();
            // uploadForm.append("file", file);
            // let contentLength = 0;
            // const fdResp = new Response(uploadForm);
            // const fdBlob = await fdResp.blob();
            // contentLength = fdBlob.size

            const basicOptions = {
                ttl: expiresIn,
                fileName: file.name,
                fileSize: file.size,
                fileContentType: file.type,
            };
            const additionalOptions = {
                recipient: recipients,
                notifyRecipient: showRecipientsBox,
            };

            const postBody = { ...basicOptions, ...additionalOptions };
            console.log('Body', postBody);
            const headers = {}
            if (userSession) {
                headers['Authorization'] = userSession.tokens.idToken.toString();
            }
            const postOp = post({
                apiName: getApiName(user),
                path: '',
                options: {
                    body: postBody,
                    headers: headers,
                }
            });
            // const postOp = post({
            //     apiName: getApiName(user),
            //     path: '/',
            //     body: postBody,
            // });
            const response = await postOp.response;
            const responseJson = await response.body.json();
            console.log('Give Created: ', responseJson)
            const token = responseJson.token;
            const presignPost = responseJson.presignPost;

            // const uploadForm = new FormData();
            // uploadForm.append("file", file);
            // uploadForm.append('Content-Type', 'multipart/form-data');
            // uploadForm.append('Content-Type', file.type);
            // Object.entries(presignPost.fields).forEach(([field, value]) => {
            //     uploadForm.append(field, value);
            // });
            const requestHeaders = new Headers();
            Object.entries(presignPost.fields)
                // .filter(([f,v]) => f === 'Host')
                .forEach(([field, value]) => {
                    // uploadForm.append(field.toLowerCase(), value);
                    requestHeaders.append(field, value[0]);
                });

            // for (const [key, prop] of uploadForm.entries()) {
            //     const itemLength = typeof prop === 'string'? prop.length: prop.size;
            //     contentLength += itemLength;
            // }
            // const contentLength = Array.from(uploadForm.entries(), ([key, prop]) => (
            //     {[key]: {
            //         "ContentLength": 
            //         typeof prop === "string" 
            //         ? prop.length 
            //         : prop.size
            //         }
            //     }
            // ));
            // requestHeaders.append('Content-Length', file.size);
            const uploadResponse = await fetch(presignPost.url, {
                method: presignPost.method,
                body: file,
                headers: requestHeaders,
            });
            // const uploadResponse = await fetch(presignPost.url, {
            //     method: presignPost.method,
            //     body: uploadForm,
            //     headers: requestHeaders,
            // });

            const responseText = await uploadResponse.text();
            console.log('Done: Uploading file to:', responseText);
            if (!uploadResponse.ok) {
                const responseXml = new DOMParser().parseFromString(responseText, "text/xml");
                const error = responseXml.getElementsByTagName('Error')[0];
                const code = error.getElementsByTagName('Code')[0].innerHTML;
                if (code === 'EntityTooLarge') {
                    throw new Error(`File is too big. Your plan allows for a maximum file size of ${(maxFileSize/1024/1024).toFixed(2)} MB.`);
                }
                throw new Error(`Ooops, an unexpected error occured. [Code: ${code}]`);
            }

            // setExpiresIn(formJson.ttl);

            props.onUploadComplete && props.onUploadComplete({
                presignUrl: `https://www.giveafile.com/token/${token}`
            });
        } catch(e) {
            if (e.response && e.response.data) {
                console.log('Response', e.response)
                const responseData = e.response.data;
                const errorDetails = responseData.error || {
                    code: 'Unknown',
                    options: {
                        userMessage: responseData.message || ''
                    }
                };
                const errorCode = errorDetails.code;
                let userMessage = '';

                if (errorCode === 'FILE_TOO_BIG') {
                    userMessage = `${errorDetails.options.userMessage} Maximum file size: ${errorDetails.options.maxFileSize? prettyBytes(errorDetails.options.maxFileSize): "Unknown"}`;
                } else {
                    userMessage = errorDetails.options.userMessage;
                }

                if (userMessage) {
                    error(userMessage);
                } else {
                    error(`${e}`);
                }
            } else {
                error('An unexpected error occured. Please try again');
            }
        }
    }

    const onFileChange = () => {

    };

    const getIconForFileType = (fileType) => {
        let icon = fileTypeIconMap[fileType];
        if (!icon) {
            icon = faFile;
        }

        return icon;
    }

    function Thumbnail() {
        let thumbnail = <></>;

        if (filelist.length > 0) {
            const firstFile = filelist[0];
            const fileType = firstFile['type'];
            const isImage = fileType.split('/')[0] === 'image';

            if (isImage) {
                thumbnail = <img className="rounded-lg object-contain" src={filePreview} alt="" width="384" height="384" />
            } else {
                const icon = getIconForFileType(fileType);
                thumbnail = <FontAwesomeIcon icon={icon} size="4x" className="text-zinc-500" />
                // thumbnail = <div className="flex h-72"><iframe content={`${fileType};charset=UTF-8`} className="rounded-none object-contain h-full" src={filePreview} alt="" width="784" /></div>
            }
        }

        return (
            <div className="flex flex-col justify-center h-72 overflow-auto">
                {thumbnail}
            </div>
        );
    }

    function FilePreview() {
        const file = filelist.length > 0? filelist[0]: {};

        return (
            <div className="flex flex-col justify-around content-around gap-5">
                <Thumbnail />
                <div className="text-zinc-500 text-sm leading-tight gap-2 flex flex-col items-center">
                    <div>{file.name}</div>
                    <div>{file.type}</div>
                    <div>{prettyBytes(file.size)}</div>
                </div>
            </div>
        );
    }

    function Dropbox() {
        const uploadInstructions = <>
            <div className="text-center text-zinc-500 text-sm font-['Geist Variable'] leading-tight">Drop a file here or click to select</div>
            <div className="text-center text-zinc-500 text-sm font-['Geist Variable'] leading-tight">{prettyBytes(maxFileSize || 0)} max</div>
        </>;

        return (
            <div className={`w-[784px] h-96 rounded-xl border-2 border-zinc-200 justify-center items-center inline-flex ${isDragActive? 'bg-gray-100': 'bg-white'}`}
                onDrop={onDrop}
                onDragLeave={onDrag}
                onDragOver={onDrag}
            >
                <div className="self-stretch flex-col justify-center items-center gap-4 inline-flex">
                    <div className="flex-col justify-start items-center gap-2 flex">
                        <input type="file" multiple name="fields[assetsFieldHandle][]" id="assetsFieldHandle" 
                              className="w-px h-px opacity-0 overflow-hidden absolute" onChange={onFileChange} accept=".pdf,.jpg,.jpeg,.png" />
                        <label htmlFor="assetsFieldHandle" className="cursor-pointer flex flex-col justify-center">
                            { filePreview
                                ? <FilePreview />
                                : uploadInstructions
                            }
                        </label>
                    </div>
                </div>
            </div>
        );
    }

    function Error() {
        return (
            <div>
                <FontAwesomeIcon icon={faExclamationTriangle} size="lg" className="text-red-500" /> 
                {uploadError}
            </div>
        );
    }

    return (
        <section className="w-full sm:px-6 lg:px-8 flex flex-col gap-10 items-center">
            <div className="flex-col justify-start items-center gap-8 flex">
                <div className="text-center text-zinc-800 text-[56px] font-['Rethink Sans'] leading-[64px]">
                    The safe way to give a file
                </div>
                <div className="w-[640px] text-center text-zinc-500 text-base font-['Geist Variable'] leading-normal">
                    { uploadError
                        ? <Error />
                        : 'Just upload your file and get a time-based link to share. Once the link expires, the file will be removed from our servers.'
                    }
                </div>
            </div>

            {/* Upload box */}
            <Dropbox />
            {/* { filePreview? <FilePreview />: <Dropbox /> } */}

            <DurationBox defaultValue={expiresIn} onChange={(newVal) => setExpiresIn(newVal)} />
            { showAdditionalOptions &&
                <div className="px-5 py-4 bg-white rounded-xl border border-gray-200 flex-col justify-start items-start gap-4 flex grow shrink">
                    <div className="self-stretch flex-col justify-start items-start gap-4 flex">
                        <div className="w-full items-center gap-2 grid grid-cols-2">
                            <GAFToggle
                                active={showRecipientsBox}
                                onClick={(isActive) => {
                                    setShowRecipientsBox(isActive);
                                }}
                            >
                                Notify recipient
                                <div className="group relative inline-flex hover:cursor-pointer">
                                    <FontAwesomeIcon icon={faQuestionCircle} className="ms-1"/>
                                    <Tooltip>Automatically send an email to the recipient notifying them that a file is waiting.</Tooltip>
                                </div>
                            </GAFToggle>
                            <input type="email"
                                key="recipientsText"
                                placeholder="Enter recipients' email address."
                                value={recipients}
                                onChange={e => setRecipients(e.target.value)}
                                disabled={!showRecipientsBox}
                                size="32"
                            />
                        </div>
                    </div>
                </div>
             }
                {/* <AdditionalOptions */}
                {/*     recipients={additionalOptions['recipients']} */}
                {/*     onChange={handleAdditionalOptions} */}
                {/* /> */}

            <GAFButton onClick={upload} type="primary">Give a file</GAFButton>
        </section>
    );
}

export function GiveFileForm() {
    const [ isWaiting, setIsWaiting ] = useState(false);
    const [ expiresIn, setExpiresIn ] = useState(3600);
    // const [ file ] = useState({});
    const [ filelist, setFilelist ] = useState([]);
    const [ filePreview, setFilePreview ] = useState('');
    const [ uploadError, setUploadError ] = useState('');
    const [ presignUrl, setPresignUrl ] = useState('');
    const [ showConfirmation, setShowConfirmation ] = useState(false);
    const [ maxFileSize, setMaxFileSize ] = useState(0);
    const [ user, setUser ] = useState();
    const [ userSession, setUserSession ] = useState();

    const additionalOptions = {};
    // const [additionalOptions, setAdditionalOptions] = useState({});

    const loadConstraints = async (user, userSession) => {
        let apiName;
        if (user) {
            apiName = 'giveafileApi';
        } else {
            apiName = 'giveafilePublicApi';
        }

        try {
            const headers = {};
            if (userSession) {
                headers['Authorization'] = userSession.tokens.idToken.toString();
            }
            const claimsOp = get({
                apiName: getApiName(user),
                path: '/constraints',
                options: {
                    headers: headers,
                },
            });
            const response = await claimsOp.response;
            const constraints = await response.body.json();
            setMaxFileSize(constraints['maxFileSize']);
        } catch(e) {
            console.error('Exception', e);
            setUploadError('An unexpected error getting user claims. Please try again.')
        }
    };

    useEffect(() => {
        setIsWaiting(true);
        (async () => {
            let user;
            let userSession;
            try {
                user = await getCurrentUser();
                userSession = await fetchAuthSession();

                setUser(user);
                setUserSession(userSession);
            } catch (e) {
                console.error(e);
            }

            await loadConstraints(user, userSession);
            setIsWaiting(false);
        })();
    }, []);

    const handleAdditionalOptions = (options) => {
        Object.assign(additionalOptions, options);
    }

    function TokenConfirmation() {
        return (
            <>
                <Confirmation presignUrl={presignUrl} ttlSeconds={expiresIn} />
                <GAFButton
                    type="primary"
                    onClick={() => {setShowConfirmation(false); setFilelist([]); setFilePreview(false);}}
                >
                    Give another
                </GAFButton>
            </>
        );
    }

    return (
        <>
        { isWaiting && <Spinner /> }
        {uploadError &&
            <div className="text-center text-zinc-500 text-base font-['Geist Variable'] leading-normal flex gap-1">
                <FontAwesomeIcon icon={faExclamationTriangle} size="lg" className="text-red-500" /> 
                { uploadError }
            </div>
        }
        { showConfirmation
            ? <TokenConfirmation />
            : <FileUploadForm 
                    user={user}
                    userSession={userSession}
                    maxFileSize={maxFileSize}
                    onUploadStart={() => setIsWaiting(true)}
                    onUploadComplete={({presignUrl, error}) => {
                        setIsWaiting(false);
                        if (presignUrl) {
                            setPresignUrl(presignUrl);
                            setShowConfirmation(true);
                        }
                    }}
              />
        }
        </>
    );
}

