import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { Amplify } from 'aws-amplify';
import amplifyconfig from './amplifyconfiguration.json';
// import awsExports from "./aws-exports";
import "./index.css";
import { getApiConfig } from './api.config';

// Amplify.configure(awsExports);
Amplify.configure(amplifyconfig);

const currentConfig = Amplify.getConfig();
const apiConfig = getApiConfig();

Amplify.configure({
    ...currentConfig,
    Auth: {
        Cognito: apiConfig.auth,
    },
    API: {
        ...currentConfig.API,
        REST: {
            ...currentConfig.API?.REST,
            ...apiConfig.api.rest,
            // giveafileApi: {
            //     endpoint: 'https://g30dt6ay8j.execute-api.us-east-1.amazonaws.com/dev',
            //     region: 'us-east-1'
            // },
            // giveafilePublicApi: {
            //     endpoint: 'https://7fmwz8i2qi.execute-api.us-east-1.amazonaws.com/dev',
            //     region: 'us-east-1'
            // }
        }
    },
    Storage: {
        S3: {
            bucket: 'giveafile-dropbox-dev',
            region: 'us-east-1',
        },
    },
});

    //<React.StrictMode>
    //</React.StrictMode>
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <App />
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

