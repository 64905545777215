const { useState } = require('react');

export function DurationBox(props) {
    const durations = [ 3600, 21600, 86400, 172800 ]; // in seconds
    const [selected, setSelected] = useState(props.defaultValue || 3600);

    const onChange = (newValue) => {
        setSelected(newValue);
        props.onChange && props.onChange(newValue);
    }
    return (
        <div className="w-[345px] h-[46px] p-1 bg-white rounded-xl border border-gray-200 justify-start items-center gap-1 inline-flex">
            <div className="px-4 py-1.5 rounded-lg justify-start items-center gap-2.5 flex">
                <div className="text-zinc-500 text-sm font-['Geist Variable'] leading-[18px]">Expires in</div>
            </div>
            {durations.map(duration =>
                <button
                    className={
                        `px-4 py-1.5 rounded-lg justify-start items-center gap-2.5 flex ${selected===duration? "bg-gray-300": ""}`
                    }
                    onClick={() => onChange(duration)}
                    key={duration}
                >
                    <div className="text-zinc-500 text-sm font-['Geist Variable'] leading-normal">{duration/3600}h</div>
                </button>
            )}
        </div>
    );
}

