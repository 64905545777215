import { isTodayDate } from './DateUtils';

export function capitalize(s) {
    return s.charAt(0).toUpperCase() + s.substring(1);
}

export function formatTimeDuration(ms) {
    const h = Math.floor((ms / (60*60*1000)));
    const m = Math.floor((ms / (1000*60)) % 60);

    return  `${h} hours, ${m} minutes`;
}

export function formatExpirationTime(dateTimeStr) {
    if (!dateTimeStr) {
        return "Unknown";
    }
    // 2024-04-30T16:02:26Z
    const date = new Date(dateTimeStr);
    const time = date.getTime();

    const dateDisplay = isTodayDate(date) && date.getTime() > Date.now()
        ? 'Today'
        : date.toDateString();
    const timeDisplay = `${date.toLocaleTimeString()}`;
    const timeLeft = formatTimeDuration(time - Date.now());

    return `${dateDisplay} ${timeDisplay} ${dateDisplay==='Today'? `(in ${timeLeft})`: ''}`;
}

export function formatDateTime(dateStr) {
    if (!dateStr) { return ''; }

    const time = Date.parse(dateStr);
    const date = new Date();
    date.setTime(time);

    return date.toDateString() + ' ' + date.toLocaleTimeString();
}

export function formatDate(dateStr) {
    if (!dateStr) { return ''; }

    const time = Date.parse(dateStr);
    const date = new Date();
    date.setTime(time);

    return date.toDateString();
}

export function formatCurrency(amount) {
    if (isNaN(amount)) {
        return 'Not Available';
    }

    return new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(amount/100);
}

