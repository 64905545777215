import { faFileCheck, faHandPointUp } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";

import "./Confirmation.css";

function Confirmation(props) {
    const [isBounce, setIsBounce] = useState(false);

    function ttlFormatted() {
        const seconds = props.ttlSeconds;
        const d = Number(seconds);
        const h = Math.floor(d / 3600);
        const m = Math.floor(d % 3600 / 60);
        const s = Math.floor(d % 3600 % 60);

        const hDisplay = h > 0 ? h + (h == 1 ? " hour, " : " hours, ") : "";
        const mDisplay = m > 0 ? m + (m == 1 ? " minute, " : " minutes, ") : "";
        const sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
        let duration = hDisplay + mDisplay + sDisplay;

        if (duration.endsWith(', ')) {
            duration = duration.slice(0, -2);
        }

        return duration;
    }

    function getExpirationDate() {
        const expirationDate = new Date();
        expirationDate.setSeconds(expirationDate.getSeconds() + props.ttlSeconds);

        return expirationDate;
    }

    function copyToClipboard() {
        const url = props.presignUrl;
        setIsBounce(true);
        setTimeout(() => { setIsBounce(false) }, 3000);
        if (navigator.clipboard) {
            navigator.clipboard.writeText(url).then(() => {
            }, function (err) {
                console.log('Error copy to clipboard', err);
            });
        } else {
            fallbackCopyToClipboard(url);
        }
    }

    function fallbackCopyToClipboard() {
        let textArea = document.createElement('textarea');
        textArea.value = props.presignUrl;
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();

        try {
            const successful = document.execCommand('copy');
            if (!successful) {
                console.log('Fallback: Copying text command was unsuccessful');
            }
        } catch (err) {
            console.error('Fallback: Oops, unable to copy', err);
        }

        document.body.removeChild(textArea);
    }

    return (
        <div>
            <div className="md:flex md:flex-col rounded-xl p-8 md:p-0 text-center">
                <div className="text-center text-zinc-800 text-[56px] font-['Rethink Sans'] leading-[64px]">Your link is ready</div>
                <div className="pt-7 text-zinc-500">
                    <i className="fal fa-link"></i>
                    <div onClick={copyToClipboard} tooltip="Copy Link"
                        className={`pointy ${isBounce && 'animate-bounce text-green-500'}`}>
                        {props.presignUrl}
                    </div>
                    <span className="font-sm font-light">
                        <FontAwesomeIcon icon={faHandPointUp} />
                        &nbsp;Click to copy&nbsp;
                        <FontAwesomeIcon icon={faHandPointUp} />
                    </span>
                    <div className="py-5 text-zinc-500">
                        <small>This link will expire on <strong>{getExpirationDate().toLocaleString()}</strong>.
                            That's <em>{ttlFormatted()}</em> from now
                            (or <span>{props.ttlSeconds}</span> seconds).
                            <div>
                                <strong>Beware:</strong> After the expiration time, the file will be 
                                <span className="text-red-500"> deleted </span> from our servers.
                            </div>
                        </small>
                    </div>
                    <div className="py-5">
                        <p>
                        <strong>Copy</strong> the above link and <strong>paste</strong> it in an email or text message to a recipient,
                            which will then use the link to access the document before expiration.
                        </p>
                        <p className="text-center">
                            <u>It's that simple</u>.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Confirmation;

