import { useState } from 'react';
import { post } from 'aws-amplify/api';
import Confirmation from './Confirmation';
import dashboardGuest from '../assets/dashboard.png';
import recipientImg from '../assets/recipient.png';
import zipImage from '../assets/zipImage.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle, faFile, faFileCode, faFilePdf, faFileZip } from '@fortawesome/pro-light-svg-icons';
import { Spinner } from './Spinner';
import { GAFButton } from './GAFButton';
import { GiveFileForm } from './GiveFileForm';
import { useNavigate } from 'react-router-dom';

// const { useState } = require('react');
const { DurationBox } = require('./DurationBox');
const prettyBytes = require('pretty-bytes');

export default function GuestDashboard() {
    const navigate = useNavigate();

    const [ isWaiting, setIsWaiting ] = useState(false);
    const [ expiresIn, setExpiresIn ] = useState(3600);
    // const [ file ] = useState({});
    const [ filelist, setFilelist ] = useState([]);
    const [ isDragActive, setIsDragActive ] = useState(false);
    const [ filePreview, setFilePreview ] = useState('');
    const [ uploadError, setUploadError ] = useState('');
    const [ presignUrl, setPresignUrl ] = useState('');
    const [ showConfirmation, setShowConfirmation ] = useState(false);

    const onFileChange = () => {

    };

    const onDrop = (event) => {
        event.preventDefault();
        event.stopPropagation();

        const droppedFiles = event.dataTransfer.files;
        setFilelist([...droppedFiles]);

        setIsDragActive(false);
        if (droppedFiles.length > 0) {
             const firstFile = droppedFiles[0];
            setFilePreview(URL.createObjectURL(firstFile))
        }
    }

    const onDrag = (event) => {
        event.preventDefault();
        event.stopPropagation();

        if (event.type === "dragenter" || event.type === "dragover") {
            setIsDragActive(true);
        } else if (event.type === "dragleave") {
            setIsDragActive(false);
        }
    }

    const upload = async (event) => {
        event.preventDefault();

        setIsWaiting(true);
        setUploadError('');

        if (filelist.length == 0) {
            setUploadError('No files selected to upload');
            setIsWaiting(false);
            return;
        }

        try {
            // const form = event.target;
            // const formData = new FormData(form);
            // const formJson = Object.fromEntries(formData.entries());
            const file = filelist[0];

            const response = await post('giveafileApi', '/token', {
                body: {
                    ttl: expiresIn,
                    fileName: file.name,
                    fileSize: file.size,
                    fileContentType: file.type,
                }
            });
            const token = response.token;
            const presignPost = response.presignPost;

            const uploadForm = new FormData();
            uploadForm.append('Content-Type', file.type);
            Object.entries(presignPost.fields).forEach(([field, value]) => {
                uploadForm.append(field, value);
            });
            uploadForm.append("file", file);
            const uploadResponse = await fetch(presignPost.url, {
                method: 'POST',
                body: uploadForm
            });

            const responseText = await uploadResponse.text();
            if (!uploadResponse.ok) {
                const responseXml = new DOMParser().parseFromString(responseText, "text/xml");
                const error = responseXml.getElementsByTagName('Error')[0];
                const code = error.getElementsByTagName('Code')[0].innerHTML;
                if (code === 'EntityTooLarge') {
                    const maxSize = error.getElementsByTagName('MaxSizeAllowed')[0].innerHTML;
                    throw new Error(`File is too big. Your plan allows for a maximum file size of ${(maxSize/1024/1024).toFixed(2)} MB.`);
                }
                throw new Error(`Ooops, an unexpected error occured. [Code: ${code}]`);
            }

            // setExpiresIn(formJson.ttl);

            setPresignUrl(`https://www.giveafile.com/#/get?token=${token}`);
            setShowConfirmation(true);
        } catch(e) {
            if (e.response) {
                const responseData = e.response.data;
                console.log('responseData', responseData)
                const message = responseData['userMessage'];
                if (message) {
                    setUploadError(message);
                } else {
                    setUploadError(`${e}`);
                }
            } else {
                setUploadError('An unexpected error occured. Please try again');
            }
        } finally {
            setIsWaiting(false);
        }
    }

    const fileTypeIconMap = {
        'application/pdf': faFilePdf,
        'application/json': faFileCode,
        'application/zip': faFileZip,
    }
    const getIconForFileType = (fileType) => {
        let icon = fileTypeIconMap[fileType];
        if (!icon) {
            icon = faFile;
        }

        return icon;
    }

    function Thumbnail() {
        let thumbnail = <></>;

        if (filelist.length > 0) {
            const firstFile = filelist[0];
            const fileType = firstFile['type'];
            const isImage = fileType.split('/')[0] === 'image';

            if (isImage) {
                thumbnail = <img className="rounded-lg object-contain" src={filePreview} alt="" width="384" height="384" />
            } else {
                const icon = getIconForFileType(fileType);
                thumbnail = <FontAwesomeIcon icon={icon} size="4x" className="text-zinc-500" />
                // thumbnail = <div className="flex h-72"><iframe content={`${fileType};charset=UTF-8`} className="rounded-none object-contain h-full" src={filePreview} alt="" width="784" /></div>
            }
        }

        return (
            <div className="flex flex-col justify-center h-72 overflow-auto">
                {thumbnail}
            </div>
        );
    }

    function FilePreview() {
        const file = filelist.length > 0? filelist[0]: {};

        return (
            <div className="flex flex-col justify-around content-around gap-5">
                <Thumbnail />
                <div className="text-zinc-500 text-sm leading-tight gap-2 flex flex-col items-center">
                    <div>{file.name}</div>
                    <div>{file.type}</div>
                    <div>{prettyBytes.default(file.size)}</div>
                </div>
            </div>
        );
    }

    function Dropbox() {
        const uploadInstructions = <>
            <div className="text-center text-zinc-500 text-sm font-['Geist Variable'] leading-tight">Drop a file here or click to select</div>
            <div className="text-center text-zinc-500 text-sm font-['Geist Variable'] leading-tight">10 MB max</div>
        </>;

        return (
            <div className={`w-[784px] h-96 rounded-xl border-2 border-zinc-200 justify-center items-center inline-flex ${isDragActive? 'bg-gray-100': 'bg-white'}`}
                onDrop={onDrop}
                onDragLeave={onDrag}
                onDragOver={onDrag}
            >
                <div className="self-stretch flex-col justify-center items-center gap-4 inline-flex">
                    <div className="flex-col justify-start items-center gap-2 flex">
                        <input type="file" multiple name="fields[assetsFieldHandle][]" id="assetsFieldHandle" 
                              className="w-px h-px opacity-0 overflow-hidden absolute" onChange={onFileChange} accept=".pdf,.jpg,.jpeg,.png" />
                        <label htmlFor="assetsFieldHandle" className="cursor-pointer flex flex-col justify-center">
                            { filePreview
                                ? <FilePreview />
                                : uploadInstructions
                            }
                        </label>
                    </div>
                </div>
            </div>
        );
    }

    function Error() {
        return (
            <div>
                <FontAwesomeIcon icon={faExclamationTriangle} size="lg" className="text-red-500" /> 
                {uploadError}
            </div>
        );
    }

    function FileUploadForm() {
        return (
            <section className="w-full sm:px-6 lg:px-8 flex flex-col gap-10 items-center">
                <div className="flex-col justify-start items-center gap-8 flex">
                    <div className="text-center text-zinc-800 text-[56px] font-['Rethink Sans'] leading-[64px]">
                        The safe way to send a file
                    </div>
                    <div className="w-[640px] text-center text-zinc-500 text-base font-['Geist Variable'] leading-normal">
                        { uploadError
                            ? <Error />
                            : 'Just upload your file and get a time-based link to share. Once the link expires, the file will be removed from our servers.'
                        }
                    </div>
                </div>

                {/* Upload box */}
                <Dropbox />
                {/* { filePreview? <FilePreview />: <Dropbox /> } */}

                <DurationBox defaultValue={expiresIn} onChange={(newVal) => setExpiresIn(newVal)} />

                <GAFButton onClick={upload}>Give a file</GAFButton>
            </section>
        );
    }

    const toLoginScreen = () => {
        navigate('/login');
    }

    function CreateAccountAd() {
        return (
            <div className="w-[1392px] h-[869px] flex-col justify-start items-center gap-10 inline-flex">
                <div className="text-center text-zinc-800 text-[40px] font-['Rethink Sans'] leading-[48px]">Create a free account<br/>to unlock all features</div>
                <div className="h-[733px] flex-col justify-start items-center gap-12 flex">
                    <div className="self-stretch h-[637px] flex-col justify-start items-start gap-6 flex">
                        <div className="self-stretch h-[378px] pl-8 pt-8 bg-white rounded-xl border border-gray-200 flex-col justify-start items-start gap-8 flex">
                            <div className="self-stretch h-16 pr-8 flex-col justify-start items-start gap-2 flex">
                                <div className="self-stretch text-zinc-800 text-2xl font-['Rethink Sans'] leading-loose">Manage uploaded files</div>
                                <div className="self-stretch text-zinc-500 text-base font-['Geist Variable'] leading-normal">Extend expiration time or revoke links.</div>
                            </div>
                            <img className="w-[750px] h-[248px] shadow" src={dashboardGuest} />
                        </div>
                        <div className="self-stretch justify-start items-start gap-6 inline-flex">
                            <div className="grow shrink basis-0 pl-8 pt-8 bg-white rounded-xl border border-gray-200 flex-col justify-start items-start gap-8 inline-flex">
                                <div className="self-stretch h-16 pr-8 flex-col justify-start items-start gap-2 flex">
                                    <div className="self-stretch text-zinc-800 text-2xl font-['Rethink Sans'] leading-loose">Secure files</div>
                                    <div className="self-stretch text-zinc-500 text-base font-['Geist Variable'] leading-normal">Pack in the archive with a password.</div>
                                </div>
                                <img className="w-[346px] h-[105px] shadow" src={zipImage} />
                            </div>
                            <div className="grow shrink basis-0 pl-8 pt-8 bg-white rounded-xl border border-gray-200 flex-col justify-start items-start gap-8 inline-flex">
                                <div className="self-stretch h-16 pr-8 flex-col justify-start items-start gap-2 flex">
                                    <div className="self-stretch text-zinc-800 text-2xl font-['Rethink Sans'] leading-loose">Share by email</div>
                                    <div className="self-stretch text-zinc-500 text-base font-['Geist Variable'] leading-normal">Open access directly to recipient.</div>
                                </div>
                                <img className="w-[346px] h-[105px] shadow" src={recipientImg} />
                            </div>
                        </div>
                    </div>
                    <GAFButton
                        type="primary"
                        onClick={toLoginScreen}
                    >Sign up for free</GAFButton>
                </div>
            </div>
        );
    }

    function TokenConfirmation() {
        return (
            <>
                <Confirmation presignUrl={presignUrl} ttlSeconds={expiresIn} />
                <GAFButton
                    onClick={() => {setShowConfirmation(false); setFilelist([]); setFilePreview(false);}}
                >
                    Give another
                </GAFButton>
            </>
        );
    }

    return (
        <>
            { isWaiting && <Spinner /> }
            <div className="w-full sm:px-6 lg:px-8 flex flex-col gap-10 items-center">
                <GiveFileForm />
                <CreateAccountAd />
            </div>
            {/* <div className="w-full sm:px-6 lg:px-8 flex flex-col gap-10 items-center"> */}
            {/*     { showConfirmation */}
            {/*         ? <TokenConfirmation /> */}
            {/*         : <FileUploadForm /> */}
            {/*     } */}
            {/*     <CreateAccountAd /> */}
            {/* </div> */}
        </>
    );
}

