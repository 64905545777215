function pbCopy(text) {
    if (navigator.clipboard) {
        navigator.clipboard.writeText(text).then(() => {
        }, function (err) {
            console.log('Error copy to clipboard', err);
        });
    } else {
        fallbackCopy(text);
    }
}

function fallbackCopy(text) {
    let textArea = document.createElement('textarea');
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
        const successful = document.execCommand('copy');
        if (!successful) {
            console.log('Fallback: Copying text command was unsuccessful');
        }
    } catch (err) {
        console.error('Fallback: Oops, unable to copy', err);
    }

    document.body.removeChild(textArea);
}

export { pbCopy };

