function ProgressBar(props) {
    function percentCompletedFormatted() {
        if (isNaN(props.percentCompleted)) {
            return 0.0;
        }

        return props.percentCompleted.toFixed(1);
    }

    return(
        <figure className="shadow h-10 text-center">
            <progress className="w-full h-10" max="100" value={props.percentCompleted}>{percentCompletedFormatted()}%</progress>
            <figcaption>{percentCompletedFormatted()}%</figcaption>
        </figure>
    );
}

export default ProgressBar;
