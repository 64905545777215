import config from "./api-exports.json";

export function getApiConfig() {
    // const buildInfo = require('../amplify/.config/local-env-info.json');
    const buildInfo = require('./local-env-info.json');
    const env = buildInfo.envName;

    return config[env];
};

