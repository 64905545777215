export default function Tooltip({children}) {
    return (
        <div
            className="pointer-events-none absolute -top-8 -left-4 w-max opacity-0 transition-opacity group-hover:opacity-100 bg-white shadow rounded-lg border border-zinc-200 px-2.5 py-0.5"
        >
            <span className="text-zinc-500 text-[13px] leading-none">
                {children}
            </span>
        </div>
    );
}

