import { getCurrentUser } from 'aws-amplify/auth';
import { generateClient } from 'aws-amplify/api';
import { getUserProfile } from '../graphql/queries';
import { updateUserProfile } from '../graphql/mutations';

export const UnknownUserProfile = {
    subscription: {
        activeFeatures: [],
        features: [],
    }
}

const apiClient = generateClient();

function calculateLimits(activeFeatures) {
    const limits = {};

    for (const feature of activeFeatures) {
        switch(feature) {
            case 'FILE_SENDS_10':
                limits['maxGives'] = 10;
                break;
            case 'FILE_SENDS_UNLIMITED':
                limits['maxGives'] = Infinity;
                break;
            case 'FILE_SIZE_6':
                limits['maxFileSize'] = 6;
                break;
            case 'FILE_SIZE_300':
                limits['maxFileSize'] = 300;
                break;
            case 'FILE_SIZE_500':
                limits['maxFileSize'] = 500;
                break;
            case 'ADVANCED_DASHBOARD':
                limits['advancedDashboard'] = true;
                break;
            case 'RECEIPT_CONFIRMATION':
                limits['receiptConfirmation'] = true;
                break;
            case 'NOTIFY_RECIPIENT':
                limits['notifyRecipient'] = true;
                break;
            case 'CONTROLS_ENABLED':
                limits['controlsEnabled'] = true;
                break;
            default:
                break;
        }
    }

    return limits;
}

export async function modifyUserProfile(userProfile) {
    const user = await getCurrentUser();

    if (!user) {
        throw new Error('No user');
    }

    const updateUserProfileResponse = await apiClient.graphql({
        query: updateUserProfile,
        variables: {
            input: {
                owner: userProfile.owner,
                useGravatarAvatar: userProfile.useGravatarAvatar
            }
        }
    });
    //     graphqlOperation(updateUserProfile, {
    //     input: {
    //         owner: userProfile.owner,
    //         useGravatarAvatar: userProfile.useGravatarAvatar
    //     }
    // }));

    return updateUserProfileResponse.data.updateUserProfile;
}

export async function fetchUserProfile() {
    const user = await getCurrentUser();
    console.log('User.js::CurrentUser', user);

    if (!user) {
        throw new Error('No user');
    }

    const userProfile = {
        email: user.signInDetails.loginId,
        limits: [],
    };
    // const userId = user.username;
    // const getUserProfileOutput = await apiClient.graphql({
    //     query: getUserProfile,
    //     variables: {
    //         input: {
    //             owner: userId,
    //         }
    //     }
    // });
    //     // graphqlOperation(getUserProfile, {owner: userId}));

    // let userProfile = getUserProfileOutput.data.getUserProfile;
    // if (!userProfile) {
    //     userProfile = UnknownUserProfile;
    // }

    // userProfile.email = user.attributes.email;

    return userProfile;
}

