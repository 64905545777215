export function Spinner() {
    return (
        <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center z-50 bg-gray-500/[.4]">
            <div
                className="block h-12 w-12 animate-spin rounded-full text-sky-500 border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                role="status"
            >
                <span
                    className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
                >
                    Loading...
                </span>
            </div>
        </div>
    );
}

