import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock, faTrashAlt } from '@fortawesome/pro-light-svg-icons';

export default function Help() {
    return(
        <div className="flex flex-col gap-4 px-6">
            <h1 className="text-zinc-800 text-[40px] font-['Rethink Sans'] leading-[48px] pb-10">
                Help
            </h1>
            <article>
                <h1 className="text-zinc-800 text-[32px] font-['Rethink Sans'] mb-8">
                    How to use
                </h1>
                <div className="text-zinc-500 text-base font-['Geist Variable'] leading-normal flex flex-col gap-4">
                    <p>
                        You can use this service to share a file with other people.
                        Each shared file has an expiration date, after which it will be 
                        deleted and the link will no longer work.
                    </p>
                    <p> The following <span className="text-green-500">options</span> are available: </p>
                    <dl className="ml-4 leading-8">
                        <dt className="font-bold">Expires in</dt>
                        <dd className="ml-2">
                            Expiration date, after which the file will no longer be available and will be deleted from giveafile.com
                        </dd>
                        <dt className="font-bold">Pack it</dt>
                        <dd className="ml-2">
                            Allows for the file to be zipped (i.e. compressed) to reduce its size, as well as to password protect it.
                        </dd>
                        <dt className="font-bold">Secure it</dt>
                        <dd className="ml-2">
                            If the file is packed, then you have the option to password protected it. This also means that the person receiving the file will need to know the password in order to unpack it. It is best practice to send the password in a separate message and not together in the same message with the file access link.
                        </dd>
                    </dl>
                </div>
            </article>

            <h1 className="text-zinc-800 text-[35px] font-['Rethink Sans'] leading-[48px] pt-10">
                Registered users only
            </h1>

            <article className="flex flex-col gap-5 leading-normal">
                <h1 className="text-zinc-800 text-[32px] font-['Rethink Sans'] leading-10">
                    Managing files
                </h1>
                <p>
                    Once you log in to your account, you can view a dashboard of all files you have given.
                    The file gives will be split into two categories:
                </p>
                <ul className="list-disc ml-8">
                    <li>Active gives</li>
                    <li>Expired gives</li>
                </ul>
                <p>
                    <strong>Active gives</strong> list all the gives that are still active. This means that someone with
                    access to the token is able to get the file.
                </p>
                <p>
                    <strong>Expired gives</strong> are gives that have expired and are presented for your information. Any file
                    associated with an expired give will be permanently deleted from our server.
                </p>
            </article>

            <article className="flex flex-col gap-5 leading-normal">
                <h1 className="text-zinc-800 text-[32px] font-['Rethink Sans'] leading-10">
                    Extending expiration time
                </h1>
                <p>
                    You can extend the expiration date of any <strong>active</strong> link for 24 hours from the dashboard.
                    This can be done using the <FontAwesomeIcon icon={faClock} /> button.
                </p>
            </article>

            <article className="flex flex-col gap-5 leading-normal">
                <h1 className="text-zinc-800 text-[32px] font-['Rethink Sans'] leading-10">
                    Deactivate a link
                </h1>
                <p>
                    You can deactivate (or expire) an <strong>active</strong> link from the dashboard using the <FontAwesomeIcon icon={faTrashAlt} /> button.
                    <strong>Note</strong> that this action will cause the associated file to be permanently deleted from our servers. Furthermore, the link details
                    will be moved under the Expired tab.
                </p>
            </article>

            <article>
                <h1 className="text-zinc-800 text-[32px] font-['Rethink Sans'] leading-10 pb-3">
                    Contact us
                </h1>
                <p className="text-zinc-500 text-base font-['Geist Variable'] leading-normal pb-3">
                    Have a questions or need support? Email us at <a href="mailto:support@giveafile.com">support@giveafile.com</a>.
                </p>
            </article>
        </div>
    );
}
