export function isTodayDate(date) {
    const today = new Date();
    return date.toDateString() === today.toDateString();
}

export function isToday(dateStr) {
    if (!dateStr) { return false; }

    const time = Date.parse(dateStr);
    const date = new Date();
    date.setTime(time);

    return isTodayDate(date);
}

