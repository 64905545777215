//App.js
import { useState, useEffect } from 'react';
import { Authenticator } from '@aws-amplify/ui-react';
import { getCurrentUser } from 'aws-amplify/auth';

//import { Protected } from './components/Protected';
import { RequireAuth } from './RequireAuth';
import { Login } from './components/Login';
//import { ProtectedSecond } from './components/ProtectSecond';
import { Layout } from './components/Layout';

import { BrowserRouter, Routes, Route } from 'react-router-dom';

import './App.css';
import { GiveFile } from './components/GiveFile';
import GetFile from './components/GetFile';
// import Pricing from './components/Pricing';
import About from './components/About';
import Dashboard from './components/Dashboard';
import GuestDashboard from './components/GuestDashboard';
import Help from './components/Help';
import TermsAndConditions from './components/TermsAndConditions';

import '@aws-amplify/ui-react/styles.css';

/*
* TODO:
*   Pass data to child components using Context. 
*   See https://react.dev/learn/passing-data-deeply-with-context
* */
function AppRoutes() {
    const [rootElement, setRootElement] = useState(<GuestDashboard />);

    useEffect(() => {
        (async () => {
            try {
                const currentUser = await getCurrentUser();
                setRootElement(<Dashboard />);
            } catch(e) {
                setRootElement(<GuestDashboard />);
            }
        })();
    }, []);

    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Layout />}>
                    <Route index element={<GuestDashboard />} />
                    <Route
                      path="/give"
                      element={ <GuestDashboard /> }
                    />
                    <Route exact path="/token/:pathToken" element={<GetFile />} />
                    <Route path="/about" element={<About />} />
                    <Route path="/login" element={<Login />} />

                    <Route index path="/private/dashboard"
                        element={
                            <RequireAuth>
                                <Dashboard />
                            </RequireAuth>
                        }
                    />
                    <Route
                      path="/private/give"
                      element={ <GiveFile /> }
                    />
                    <Route
                      path="/terms"
                      element={
                          <TermsAndConditions />
                      }
                    />
                    <Route
                      path="/help"
                      element={
                          <Help />
                      }
                    />
                </Route>
            </Routes>
        </BrowserRouter>
    );
}

function App() {
  return (
    <Authenticator.Provider>
      <AppRoutes />
    </Authenticator.Provider>
  );
}

export default App;

