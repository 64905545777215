import { useState, useEffect, useCallback, Fragment } from 'react';
import { graphqlOperation } from 'aws-amplify';
import { generateClient, del, get, put } from 'aws-amplify/api';
import { getCurrentUser, fetchAuthSession } from 'aws-amplify/auth';
import { listFiles, getFileCount } from '../graphql/queries';
import { updateFiles } from '../graphql/mutations';
import { formatDate, formatExpirationTime } from '../services/Formatter';
import { fetchUserProfile } from '../services/User';
import { pbCopy } from '../services/Pasteboard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faArrowUp, faArrowDownArrowUp, faChevronDown, faClock, faGrid2, faHashtag, faLink, faList, faQrcode, faRefresh, faSearch, faTrashAlt } from '@fortawesome/pro-light-svg-icons';
import QRCode from 'qrcode';
import QRCodeModal from './QRCodeModal';

import './Dashboard.css';
import Tooltip from './Tooltip';
import Toast from './Toast';
import { Spinner } from './Spinner';
import { GAFButton } from './GAFButton';

const gqlClient = generateClient();

function basename(path) {
    let separator = '/'

    const windowsSeparator = '\\'

    if (path.includes(windowsSeparator)) {
        separator = windowsSeparator
    }

    return path.slice(path.lastIndexOf(separator) + 1)
}

function fileName(fileGive) {
    const s3Key = fileGive.s3Key;

    return s3Key? decodeURIComponent(basename(s3Key)): '';
};

function fileExtension(filename) {
    const sepIndex = filename.lastIndexOf('.');
    if (sepIndex > -1) {
        return filename.substring(sepIndex+1, filename.length);
    }

    return '';
}

const identityFilter = (data) => {
    return data;
}

function TabBar(props) {
    const [activeTab, setActiveTab] = useState(props.initialActiveTab || 0);
    const tabs = [
        {
            name: 'active',
            count: props.activeTabCount || 0,
        },
        {
            name: 'expired',
        }
    ];

    const handleOnClick = (tabIndex) => {
        setActiveTab(tabIndex);
        if (props.onChange && typeof props.onChange === 'function') {
            props.onChange(tabIndex);
        }
    }

    return (
        <div className={`justify-start items-center gap-6 inline-flex ${props.className}`}>
            {tabs.map( (tab, index) => 
                <div className="py-2 justify-start items-center flex flex-col gap-2"
                    key={index}
                    onClick={() => handleOnClick(index)}
            >
                    <div
                        className={`${activeTab===index? 'text-zinc-800': 'text-zinc-500'} cursor-pointer text-sm font-['Geist Variable'] leading-normal`}>
                        {tab.name}
                        {tab.count !== undefined && tab.count !== null
                            ? <span className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20 ms-2">
                                {tab.count}
                              </span>
                            : null
                        }
                    </div>
                    {activeTab === index &&
                        <div className="w-full h-[3px] bg-indigo-500" />
                    }
                </div>
            )}
        </div>
    );   
}

function TokenTable(props) {
    const [sortColumn, setSortColumn] = useState(props.sortBy || 2);
    const [sortDirection, setSortDirection] = useState('asc');
    const [isBusy, setIsBusy] = useState(false);
    const rowData = props.rowData || [];
    const colDefs = props.colDefns;
    const showActions = props.showActionColumn;

    const tdClassName =  "text-sm font-['Geist Variable'] leading-tight px-5 py-4";
    const borderedClassName = 'rounded-md border border-gray-200 text-zinc-500 p-2';

    const updateTokenExpiration = async (fileToken, expirationDate, userMessage) => {
        // TODO: The problem with using GraphQL is that the metadata on the
        // object in S3 is not updated and so the file will not be deleted
        // according to the new expirationTime!!!
        setIsBusy(true);
        try {
            // const response = await gqlClient.graphql({
            //     query: updateFiles,
            //     variables: {
            //         input: {
            //             id: fileToken,
            //             expireTimestamp: expirationDate

            //         }
            //     }
            // });
            // const responseData = response['data'];
            props.onExtendToken && props.onExtendToken(fileToken, 24*60*60);

            props.notifyUser(userMessage);

            return ;
        } catch (e) {
            console.error(e);
        } finally {
            setIsBusy(false);
        }
    }

    const handleCopyLink = (event, fileGive) => {
        event.preventDefault();

        const token = fileGive.id.rawValue;
        const link = `${window.location.origin}/token/${token}`;
        pbCopy(link);
        props.notifyUser('Link copied to clipboard')
    }

    const handleExtendToken = async (event, fileGive) => {
        event.preventDefault();

        const fileToken = fileGive.id.rawValue;
        console.log('ExpiresOn', fileGive.expireTimestamp)
        const oldExpireTime = Date.parse(fileGive.expireTimestamp.rawValue);
        const expireTimestamp = new Date();
        expireTimestamp.setTime(oldExpireTime + (24*60*60*1000)); // Add 24 hours (expressed in ms)

        try {
            await updateTokenExpiration(
                fileToken,
                expireTimestamp.toISOString(),
               'Link extended by 24 hours'
            );
        } catch(e) {
            console.error(e);
        }
        // try {
        //     const response = await API.graphql(graphqlOperation(updateFiles, {
        //         input: {
        //             id: fileToken,
        //             expireTimestamp: expireTimestamp.toISOString()
        //         }
        //     }));
        //     const responseData = response['data'];
        //     props.onExtendToken && props.onExtendToken(responseData['updateFiles'])

        //     props.setToastMessage('Link extended by 24 hours');
        //     setTimeout(() => { props.setToastMessage('') }, 3000);
        // } catch (e) {
        //     console.error(e);
        // }
    }

    const handleGenerateQRCode = (event, fileGive) => {
        event.preventDefault();
        props.onQRCode && props.onQRCode(fileGive);
    };

    const handleExpireToken = async (event, fileGive) => {
        event.preventDefault();

        try {
            setIsBusy(true);
            props.onExpireToken && props.onExpireToken(fileGive.id.rawValue);

            props.notifyUser(
                'Link has been expired. The file associated with the link will be permanently deleted.');
        } finally {
            setIsBusy(false);
        }
    }

    const sortAndFilter = () => {
        const sorted = rowData.sort((r1,r2) => {
            const col = colDefs[sortColumn];
            const val1 = r1[col.field]['rawValue'];
            const val2 = r2[col.field]['rawValue'];

            if (val1 > val2) {
                return sortDirection==='desc'? -1: 1;
            } else if (val1 < val2) {
                return sortDirection==='desc'? 1: -1;
            } else {
                return 0;
            }
        });

        return sorted;
    }

    const toggleSortDirection = () => {
        if (sortDirection === 'asc') {
            setSortDirection('desc');
        } else {
            setSortDirection('asc');
        }
    }

    const updateSortColumn = (colIndex) => {
        if (sortColumn !== colIndex) {
            setSortColumn(colIndex);
        } else {
            toggleSortDirection();
        }
    }

    function SortIcon() {
        if (sortDirection === 'asc') {
            return <FontAwesomeIcon icon={faArrowUp} />;
        }

        return <FontAwesomeIcon icon={faArrowDown} />;
    }

    const RowActions = ({rowData, index}) => {
        return (
            <td key={`${index}-action`} className="flex gap-1 pt-2">
                <div className="group relative w-max">
                    <button className="w-8 h-8 bg-white rounded-lg shadow-inner border border-zinc-300 justify-center items-center flex"
                        onClick={(e) => handleCopyLink(e, rowData)}
                    >
                        <FontAwesomeIcon icon={faLink} /> 
                    </button> 
                    <Tooltip>Copy link</Tooltip>
                </div>
                <div className="group relative w-max">
                    <button className="w-8 h-8 bg-white rounded-lg shadow-inner border border-zinc-300 justify-center items-center flex"
                        onClick={(e) => handleExtendToken(e, rowData)}
                    >
                        <FontAwesomeIcon icon={faClock} />
                    </button> 
                    <Tooltip>Extend expiration time</Tooltip>
                </div>
                <div className="group relative w-max">
                    <button className="w-8 h-8 bg-white rounded-lg shadow-inner border border-zinc-300 justify-center items-center flex"
                        onClick={(e) => handleGenerateQRCode(e, rowData)}
                    >
                        <FontAwesomeIcon icon={faQrcode} />
                    </button> 
                    <Tooltip>Display a QR Code</Tooltip>
                </div>
                <div className="group relative w-max">
                    <button className="w-8 h-8 bg-white rounded-lg shadow-inner border border-zinc-300 justify-center items-center flex"
                        onClick={(e) => handleExpireToken(e, rowData)}
                    >
                        <FontAwesomeIcon icon={faTrashAlt} />
                    </button> 
                    <Tooltip>Delete</Tooltip>
                </div>
            </td>
        );
    }

    const Row = ({rowData, index}) => {
        return (
            <tr className="border-b border-gray-200">
                {colDefs.map((colDef, colIndex) => 
                    <td className={`${tdClassName} text-zinc-800`}
                        key={`${index}:${colIndex}`}
                    >
                        <span
                            className={`${colIndex===0? borderedClassName: ''}`}
                        >{rowData[colDef.field]['displayValue']}</span>
                    </td>
                )}
                {showActions &&
                    <RowActions rowData={rowData} index={index} />
                }
            </tr>
        );
    }

    return (
        <>
            { isBusy && <Spinner /> }
            <table className="w-full rounded-xl border border-gray-200">
                <thead>
                    <tr className="border-b border-gray-200">
                        {colDefs.map((colDefn, index) => 
                            <th className="text-zinc-500 text-[13px] font-['Geist Variable'] leading-tight py-4 px-5, cursor-pointer"
                                key={index}
                                onClick={() => updateSortColumn(index)}
                            >
                                {colDefn.displayField} {sortColumn === index && <SortIcon />}
                            </th>
                        )}
                        { showActions && 
                            <th className="text-zinc-500 text-[13px] font-['Geist Variable'] leading-tight">
                                Actions
                            </th>
                        }
                    </tr>
                </thead>
                <tbody>
                    {sortAndFilter().map((rowData, index) => (
                        <Row rowData={rowData} index={index} key={index}/>
                    ))}
                </tbody>
            </table>
        </>
    );
}

function Search({handleSearch}) {
    const [searchString, setSearchString] = useState('');

    const onChange = (e) => {
        e.preventDefault();

        const newSearchString = e.target.value;

        handleSearch(newSearchString);
        setSearchString(newSearchString);
    }

    return (
        <>
            <div className="relative"><FontAwesomeIcon icon={faSearch} /></div>
            <input type="text" placeholder="Search by filename or recipient's email"
                className="w-full text-zinc-500 border-0 text-sm font-['Geist Variable'] leading-snug"
                value={searchString}
                onChange={onChange}
                // autoFocus="autoFocus"
                key="searchbox"
            />
        </>
    );
}

export default function Dashboard() {
    const [fileGives, setFileGives] = useState([]);
    const [expiredFileGives, setExpiredFileGives] = useState([]);
    const [filteredGives, setFilteredGives] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');
    const [currentMonthGiveCount, setCurrentMonthGiveCount] = useState(null);
    const [totalGiveCount, setTotalGiveCount] = useState(null);
    const [setMaxGives] = useState(null);
    const [actionStatus, setActionStatus] = useState({});
    const [showQRCode, setShowQRCode] = useState(false);
    const [showExpired, setShowExpired] = useState(false);
    const [qrCodeDataUrl, setQrCodeDataUrl] = useState(null);
    // const [loadData, setLoadData] = useState(true);
    const [gridType, setGridType] = useState('table');
    const [toastMessage, setToastMessage] = useState('');
    const [isBusy, setIsBusy] = useState(false);

    const [ userSession, setUserSession ] = useState();

    let loadData = true;

    const [tableColDefns] = useState([
        { displayField: 'Type', field: 'contentType' }, 
        { displayField: 'Name', field: 'fileName' }, 
        { displayField: 'Expires', field: 'expireTimestamp' }, 
        { displayField: 'Shared with', field: 'recipients' }, 
        { displayField: 'Access count', field: 'accessCount' }, 
        { displayField: 'Given on', field: 'createdAt' },
    ]);
    // const [searchString, setSearchString] = useState('');

    const notifyUser = (message) => {
        setToastMessage(message);
        setTimeout(() => { setToastMessage('') }, 5000);
    }

    const doSearch = (data, options) => {
        // setSearchString(options.searchString);
        const query = options.searchString? options.searchString.toLowerCase() :'';

        const filtered = data.filter(row => {
            const s3Key = row.s3Key;
            const filename = fileName(s3Key).toLowerCase();
            const recipients = row['recipients'] || [];
            const recipientsString = recipients.join(',').toLowerCase();

            return filename.includes(query) || recipientsString.includes(query);
        });

        return filtered;
    } 

    const handleGenerateQRCode = async (file) => {
        const fileToken = file.id;
        const fileLink = createGetLink(fileToken);
        const qrCode = await QRCode.toDataURL(fileLink);
        setQrCodeDataUrl(qrCode);
        setShowQRCode(true);
    }

    const handleSearch = (searchString) => {
        const data = showExpired? expiredFileGives: fileGives;
        let filteredData;

        if (searchString) {
            filteredData = doSearch(data, {searchString});
        } else {
            filteredData = identityFilter(data);
        }
        setFilteredGives(filteredData);
        // setSearchString(searchString);
    }

    const loadUserProfile = useCallback(async () => {
        const userProfile = await fetchUserProfile();
        const limits = userProfile.limits;

        if (limits['maxGives']) {
            setMaxGives(limits['maxGives'])
        }
    }, [setMaxGives]);

    const loadExpiredFileGives = async (userSession) => {
        const headers = {};
        if (userSession) {
            headers['Authorization'] = userSession.tokens.idToken.toString();
        }
        const httpOp = get({
            apiName: "giveafileApi",
            path: "/dashboard?q=expired",
            options: {
                headers: headers,
            },
        });
        const response = await httpOp.response;
        const json = await response.body.json();
        const expiredFileGives = json.expiredFileGives;

        // const fileGives = listFilesResponse.data.listFiles.items;
        expiredFileGives.sort((a,b) => a.expireTimestamp < b.expireTimestamp);

        setExpiredFileGives(expiredFileGives);
    }

    const loadFileGives = useCallback(async (userSession) => {
        // const listFilesResponse = await API.graphql(graphqlOperation(listFiles));
        // const listFilesResponse = await gqlClient.graphql({
        //     query: listFiles,
        // });

        const headers = {};
        if (userSession) {
            headers['Authorization'] = userSession.tokens.idToken.toString();
        }
        const httpOp = get({
            apiName: "giveafileApi",
            path: `/dashboard?q=${showExpired?"expired":""}`,
            options: {
                headers: headers,
            },
        });
        const response = await httpOp.response;
        const json = await response.body.json();
        const expiredFileGives = json.expiredFileGives;
        const activeFileGives = json.fileGives;

        // const fileGives = listFilesResponse.data.listFiles.items;
        activeFileGives.sort((a,b) => a.expireTimestamp < b.expireTimestamp);

        setExpiredFileGives(expiredFileGives);
        setFileGives(activeFileGives);

        if (showExpired) {
            setFilteredGives(expiredFileGives);
        } else {
            setFilteredGives(activeFileGives);
        }

        setTotalGiveCount(json.summary.totalCount);
        setCurrentMonthGiveCount(json.summary.currentMonthCount);

    }, [setFileGives]);

    // const countFileGives = useCallback(async () => {
        // const today = new Date();
        // const month = (today.getMonth()+1).toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping: false});
        // const currentMonthYear = today.getFullYear() + month;

        // const user = await getCurrentUser();
        // const owner = user.username;
        // let fileCount = 0;
        // let totalFileCount = 0;
        // try {
            // const getCountResponse = await API.graphql(graphqlOperation(getFileCount, {
            //     owner: owner,
            //     objectName: 'GIVES',
            //     period: currentMonthYear
            // }));
            // const getCountResponse = await gqlClient.graphql({
            //     query: getFileCount,
            //     variables:{
            //         input: {
            //             owner: owner,
            //             objectName: 'GIVES',
            //             period: currentMonthYear
            //         }
            //     }
            // });
            // let getFileCountData = getCountResponse.data.getFileCount;
            // if (getFileCountData) {
            //     fileCount = getFileCountData.objectCount;
            // }

            // if (fileCount) {
            // }

            // const getTotalCountResponse = await gqlClient.graphql({
            //     query: getFileCount,
            //     variables: {
            //         input: {
            //             owner: owner,
            //             objectName: 'GIVES',
            //             period: 'Total'
            //         }
            //     }
            // });
            // const getTotalCountResponse = await API.graphql(graphqlOperation(getFileCount, {
            //     owner: owner,
            //     objectName: 'GIVES',
            //     period: 'Total'
            // }));
            // getFileCountData = getTotalCountResponse.data.getFileCount;
            // if (getFileCountData ) {
            //     totalFileCount = getFileCountData.objectCount;
            // }
        // } catch (error) {
        //     console.error(error);
        // }
        // setCurrentMonthGiveCount(fileCount);
        // setTotalGiveCount(totalFileCount);
    // }, [setCurrentMonthGiveCount, setTotalGiveCount]);


    const tableData = () => {
        const tableRow = (raw, display) => ({
            rawValue: raw,
            displayValue: display || raw
        });

        const gives = showExpired? expiredFileGives: fileGives;
        return gives.map(fileGive => {
            const theFilename = fileGive['fileName'];
            const contentTypeRaw = fileGive['fileContentType'] || '';
            const contentTypeParts = contentTypeRaw.split('/');
            const contentType = contentTypeParts.length === 1? contentTypeParts[0]: contentTypeParts[1];
            const theFileExtension = `.${fileExtension(theFilename)}`;
            const recipient = fileGive.recipient;
            const recipientDisplay = recipient? recipient: 'Everyone';

            return {
                id: tableRow(fileGive.id),
                accessCount: tableRow(fileGive.accessCount),
                confirmReceipt: tableRow(fileGive.confirmReceipt),
                contentLength: tableRow(fileGive.contentLength),
                contentType: tableRow(contentType || theFileExtension),
                createdAt: tableRow(fileGive.createdDate, formatDate(fileGive.createdDate)),
                expireTimestamp: tableRow(fileGive.expireDate, formatExpirationTime(fileGive.expireDate)),
                fileName: tableRow(theFilename),
                notifyRecipient: tableRow(fileGive.notifyRecipient),
                owner: tableRow(fileGive.owner),
                recipients: tableRow(recipient, recipientDisplay),
                s3Key: tableRow(fileGive.s3Key),
                updatedAt: tableRow(fileGive.updatedAt, formatDate(fileGive.updatedAt)),
                zipIt: tableRow(fileGive.zipIt),
                zipPassword: tableRow(fileGive.zipPassword),
            }
        });
    }

    const bool2String = (boolVal) => {
        return boolVal ? 'Yes': 'No';
    }

    const isExpired = (datetime) => {
        const now = Date.now();
        const anotherDate = Date.parse(datetime);

        return now > anotherDate;
    }

    const createGetLink = (fileToken) => {
        return `https://www.giveafile.com/get/${fileToken['rawValue']}`;
    }

    const handleCopyToken = (file, index) => {
        setActionStatus({
            index: index,
            message: 'Copied'
        });

        const fileToken = file.id;
        const url = `${fileToken}`;
        pbCopy(url);

        setTimeout(() => {
            setActionStatus({
                index: index,
                message: ''
            });
        }, 1000);
    }

    const handleCopyLink = (file, index) => {
        setActionStatus({
            index: index,
            message: 'Copied'
        });

        const fileToken = file.id;
        const url = createGetLink(fileToken);
        pbCopy(url);

        setTimeout(() => {
            setActionStatus({
                index: index,
                message: ''
            });
        }, 1000);
    }

    const handleExtendExpiration = async (file, index) => {
        const fileToken = file.id;
        const oldExpireTime = Date.parse(file.expireTimestamp);
        const expireTimestamp = new Date();
        expireTimestamp.setTime(oldExpireTime + (24*60*60*1000)); // Add 24 hours (expressed in ms)

        try {
            await gqlClient.graphql({
                query: updateFiles,
                variables: {
                    input: {
                        id: fileToken,
                        expireTimestamp: expireTimestamp.toISOString()
                    }
                }
            })
            // await API.graphql(graphqlOperation(updateFiles, {
            //     input: {
            //         id: fileToken,
            //         expireTimestamp: expireTimestamp.toISOString()
            //     }
            // }));
            setActionStatus({
                index: index,
                message: 'Extended by 24h'
            });
            await loadFileGives();
            setTimeout(() => {
                setActionStatus({
                    index: index,
                    message: ''
                });
            }, 1000);
        } catch (e) {
            console.error(e);
        }
    }

    const handleTabChange = (tabIndex) => {
        const showExpired = tabIndex === 1;
        if (showExpired) {
            loadExpiredFileGives(userSession);
            // setFilteredGives(expiredFileGives);
        } else {
            setFilteredGives(fileGives);
        }

        setShowExpired(showExpired);
    }

    const handleTokenExtended = async (updatedToken, ttl) => {
        setIsBusy(true);

        try {
            const matched = fileGives.filter(f => f.id === updatedToken);
            if (!matched) {
                return;
            }
            const op = put({
                apiName: 'giveafileApi',
                path: `/${updatedToken}`,
                options:{
                    headers: {
                        'Authorization': userSession.tokens.idToken.toString()
                    },
                    body: {
                        ttl,
                    }
                }
            });
            const response = await op.response;
            const responseJson = await response.body.json();
            const updatedFilegive = responseJson.fileGive;
            console.log(responseJson)

            const oldFileGive = matched[0];
            oldFileGive['expireDate'] = updatedFilegive['expireDate'];
            setFileGives(fileGives);
        } catch(e) {
            console.err(e);
            setErrorMessage('Oops, an error occured while extending the give. Please try again.')
        } finally {
            setIsBusy(false);
        }
    }

    const handleTokenExpire = async (expiredToken) => {
        const op = del({
            apiName: 'giveafileApi',
            path: `/${expiredToken}`,
            options:{
                headers: {
                    'Authorization': userSession.tokens.idToken.toString()
                }
            }
        });
        await op.response;

        const filtered = fileGives.filter(f => f.id !== expiredToken.id);

        setFileGives(filtered);
        expiredFileGives.push(expiredToken);
    }
    // function basename(path) {
    //     let separator = '/'

    //     const windowsSeparator = '\\'

    //     if (path.includes(windowsSeparator)) {
    //         separator = windowsSeparator
    //     }

    //     return path.slice(path.lastIndexOf(separator) + 1)
    // }

    function GridBar({handleSearch, enableGridType, onChangeGridType}) {
        return (
            <div className="w-full h-10 justify-between items-center flex">
                <div className="w-1/2 h-10 px-5 py-2 bg-white rounded-xl border border-gray-200 justify-start items-center gap-2.5 flex">
                    <Search handleSearch={handleSearch} />
                    {/* <div className="relative"><FontAwesomeIcon icon={faSearch} /></div> */}
                    {/* <input type="text" placeholder="Search by filename or recipient's email" */}
                    {/*     className="w-full text-zinc-500 border-0 text-sm font-['Geist Variable'] leading-snug" */}
                    {/*     value={searchString} */}
                    {/*     onChange={handleSearch} */}
                    {/*     autoFocus="autoFocus" */}
                    {/*     key="searchbox" */}
                    {/* /> */}
                </div>
                <div className="justify-start items-center flex">
                    <div className="px-4 py-1.5 rounded-lg justify-start items-center gap-2.5 flex">
                        <div className="relative"><FontAwesomeIcon icon={faArrowDownArrowUp} /></div>
                        <div className="text-zinc-500 text-sm font-['Geist Variable'] leading-[18px]">Expires</div>
                    </div>
                </div>
                { enableGridType && 
                    <div className="p-1 bg-white rounded-xl border border-gray-200 justify-start items-center gap-1 flex">
                        <button className={`px-4 py-1.5 ${gridType==='table' && 'bg-zinc-100'} rounded-lg justify-start items-center gap-2.5 flex`}
                            onClick={() => onChangeGridType('table')}
                        >
                            <div className="relative"><FontAwesomeIcon icon={faList} /></div>
                            <div className="text-zinc-500 text-sm font-['Geist Variable'] leading-[18px]">List</div>
                        </button>
                        <button className={`px-4 py-1.5 ${gridType==='grid' && 'bg-zinc-100'} rounded-lg justify-start items-center gap-2.5 flex`}
                            onClick={() => onChangeGridType('grid')}
                        >
                            <div className="relative"><FontAwesomeIcon icon={faGrid2} /></div>
                            <div className="text-zinc-500 text-sm font-['Geist Variable'] leading-[18px]">Grid</div>
                        </button>
                    </div>
                }
            </div>
        );
    }

    useEffect(() => {
        if (loadData) {
            loadData = false;
            // setLoadData(false);

            fetchAuthSession().then(userSession => {
                setUserSession(userSession);

                const promises = [loadFileGives(userSession), loadUserProfile()];
                // const promises = [loadFileGives(userSession), countFileGives(), loadUserProfile()];
                Promise.allSettled(promises)
                .catch(error => {
                    console.error('Error: ', error);
                    setErrorMessage(error.errors && error.errors.length > 0? error.errors[0].message: error)
                })
                .finally(() => {
                    // setLoadData(true);
                    loadData = true;
                });
            });

            // if(userSession) {
            //     const promises = [loadFileGives(userSession), countFileGives(), loadUserProfile()];
            //     Promise.allSettled(promises)
            //     .catch(error => {
            //         console.error('Error: ', error);
            //         setErrorMessage(error.errors && error.errors.length > 0? error.errors[0].message: error)
            //     })
            //     .finally(() => {
            //         // setLoadData(true);
            //         loadData = true;
            //     });
            // }
        }
    }, [loadFileGives, loadUserProfile, loadData]);

    return (
        <>
            {showQRCode && 
                <QRCodeModal qrCode={qrCodeDataUrl} onClose={() => setShowQRCode(false)} />
            }
            <div>
                <div className="w-full flex justify-center">
                    <Toast className="w-1/2">{toastMessage}</Toast>
                </div>
                {errorMessage &&
                    <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative m-5 w-1/2"
                        role="alert">
                        <strong className="font-bold pr-3">Holy smokes!</strong>
                        <span className="block sm:inline">Something seriously bad happened. Try again and let us know if this persists!</span>
                        <span className="block sm:inline">Message: {errorMessage}</span>
                        <span className="absolute top-0 bottom-0 right-0 px-4 py-3">
                            <svg className="fill-current h-6 w-6 text-red-500" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><title>Close</title><path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z"/></svg>
                        </span>
                    </div>
                }
                <div className="flex flex-col justify-start items-start gap-6" >
                    <div className="flex flex-col content-between gap-4 w-full">
                        <div className="flex justify-around">
                            <div className="w-80 grid grid-rows-3 rounded-lg bg-white shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] dark:bg-neutral-700 dark:text-neutral-50">
                                <div className="row-span-1 border-b-2 px-6 py-3 border-neutral-100 dark:border-neutral-600 text-center">
                                    <h1 className="text-2xl">This Month</h1>
                                    <h2 className="dark:text-neutral-150 text-sm">Gives</h2>
                                </div>
                                <div className="row-span-2 self-center justify-self-center text-6xl">
                                    {currentMonthGiveCount || '0'}
                                </div>
                            </div>
                            <div className="w-80 grid grid-rows-3 rounded-lg bg-white shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] dark:bg-neutral-700 dark:text-neutral-50">
                                <div className="row-span-1 border-b-2 px-6 py-3 border-neutral-100 dark:border-neutral-600 text-center">
                                    <h1 className="text-2xl">Total</h1>
                                    <h2 className="dark:text-neutral-150 text-sm">Gives</h2>
                                </div>
                                <div className="row-span-2 self-center justify-self-center text-6xl">
                                    {totalGiveCount || '0'}
                                </div>
                            </div>
                        </div>
                    </div>
                    {isBusy && <Spinner />}
                    <TabBar
                        initialActiveTab={showExpired? 1: 0}
                        activeTabCount={fileGives.length}
                        expiredTabCount={expiredFileGives.length}
                        onChange={handleTabChange}
                    />
                    <div className="w-full flex flex-col">
                        <div className="w-full h-10 justify-between items-center flex">
                            <div className="w-1/2 h-10 px-5 py-2 bg-white rounded-xl border border-gray-200 justify-start items-center gap-2.5 flex">
                                <Search handleSearch={handleSearch} />
                            </div>
                            <GAFButton
                                type="primary"
                                onClick={async () => {
                                    setIsBusy(true);
                                    try {
                                        await loadFileGives(userSession);
                                    } finally {
                                        setIsBusy(false);
                                    }
                                }}
                            >
                                <FontAwesomeIcon icon={faRefresh} spin={isBusy}/> Refresh
                            </GAFButton>
                        </div>
                    </div>
                    { gridType==='table'
                        ? <TokenTable
                            rowData={tableData()}
                            colDefns={tableColDefns}
                            showActionColumn={!showExpired}
                            notifyUser={notifyUser}
                            onExtendToken={handleTokenExtended}
                            onExpireToken={handleTokenExpire}
                            onQRCode={handleGenerateQRCode}
                        />
                        : <></>
                    }
                </div>
            </div>
        </>
    );
}

