import { Fragment, useEffect, useState, useCallback } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRightFromArc, faArrowRightToArc, faGauge, faSignOut, faUserCircle, faFileExport, faMoneyBill, faSignIn, faHome, faQuestionCircle } from '@fortawesome/pro-light-svg-icons';
import { Menu, Transition } from '@headlessui/react';
import { sha256 } from 'js-sha256';
import { fetchUserProfile, UnknownUserProfile } from '../services/User';

import logo from '../assets/giveafile-logo.svg';
import { GAFButton } from './GAFButton';
import { Footer } from './Footer.js';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export function Layout() {
    const { signOut, user } = useAuthenticator((context) => [
        context.signOut,
        context.user
    ]);

    const [userProfile, setUserProfile] = useState(UnknownUserProfile);
    const navigate = useNavigate();

    function logOut() {
        signOut();
        navigate('/login');
    }

    useEffect(() => {
        fetchUserProfile()
            .then(userProfile => {
                setUserProfile(userProfile);
            })
            .catch( err => {
                setUserProfile(UnknownUserProfile);
                console.error('fetchUserProfile:Catch', err);
            });
    }, []);

    const getGravatarURL = () => {
        const email = userProfile.email;
        const address = String(email).trim().toLowerCase();
        const hash = sha256( address );

        return `https://www.gravatar.com/avatar/${ hash }?size=28&d=404`;
    }

    function NavLinks() {
        return (
            <div className="grid grid-cols-3 gap-3 bg-blue-900/20 p-3 rounded-xl">
                {user &&
                    <>
                        <button onClick={() => navigate('/dashboard')}>
                            <FontAwesomeIcon icon={faGauge} /> Dashboard
                        </button>
                    </>
                }
                <button onClick={() => navigate('/give')}>
                    <FontAwesomeIcon icon={faArrowRightFromArc} /> Give
                </button>
                <button onClick={() => navigate('/get')}>
                    <FontAwesomeIcon icon={faArrowRightToArc} /> Get
                </button>
            </div>
        );
    }

    function UserMenu() {
        return (
            <Menu as="div" className="relative inline-block text-left">
                <div>
                    <Menu.Button className="inline-flex w-full justify-center items-center rounded-md px-4 py-2 text-sm font-medium hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
                        {/* {user.attributes.email} */}
                        { userProfile.useGravatarAvatar
                            ? <img src={getGravatarURL()} className="ml-3" />
                            : <FontAwesomeIcon icon={faUserCircle} size="2x" className="ml-3" />
                        }
                        {/* <img src={getGravatarURL()} className="ml-3" /> */}
                        {/* <FontAwesomeIcon icon={faUserCircle} size="2x" className="ml-3" /> */}
                    </Menu.Button>
                </div>
                <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                >
                    <Menu.Items className="absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <div className="px-1 py-1">
                            <Menu.Item>
                                {({}) => (
                                    <h2 className="text-black m-4 text-lg text-center">{userProfile.email}</h2>
                                )}
                            </Menu.Item>
                        </div>
                        <div className="px-1 py-1">
                            <Menu.Item>
                                {({ active }) => (
                                    <button onClick={() => navigate('/help')}
                                        className={`${active ? 'bg-blue-500 text-white' : 'text-gray-900'}
                                                group flex w-full items-center rounded-md px-2 py-2 text-lg`}>
                                        <FontAwesomeIcon icon={faQuestionCircle} className="mr-2" />
                                        Help
                                    </button>
                                )}
                            </Menu.Item>
                        </div>
                        <div className="px-1 py-1">
                            <Menu.Item>
                                {({ active }) => (
                                    <button onClick={() => logOut()}
                                        className={`${active && 'bg-blue-500'}
                                            group flex w-full items-center rounded-md px-2 py-2 text-lg text-red-500`}>
                                        <FontAwesomeIcon icon={faSignOut} className="mr-2" />
                                        Log Out
                                    </button>
                                )}
                            </Menu.Item>
                        </div>
                    </Menu.Items>
                </Transition>
            </Menu>
        );
    }

    const showDashboard = () => {
        if (user) {
            navigate('/private/dashboard');
        } else {
            navigate('/')
        }
    }

    function NavBar() {
        return (
            <div className="w-full h-10 mb-5 rounded-[28px] justify-between items-center inline-flex">
                <div className="justify-start items-center gap-3 flex">
                    <div className="w-8 h-8 relative">
                        <img src={logo} />
                    </div>
                    <div className="text-zinc-800 text-lg font-['Rethink Sans'] leading-relaxed cursor-pointer"
                        onClick={() => showDashboard()}
                    >
                        Give a file
                    </div>
                </div>
                <div className="justify-start items-center gap-8 flex">
                    {user && 
                        <div className="justify-start items-start gap-2 flex">
                            <GAFButton type="secondary"
                                onClick={() => navigate('/private/dashboard')}
                            >
                                <FontAwesomeIcon icon={faHome} className="mr-1" />
                                Dashboard
                            </GAFButton>
                            <GAFButton type="primary"
                                onClick={() => navigate('/private/give')}
                            >Give a file</GAFButton>
                            <UserMenu />
                        </div>
                    }
                    {!user &&
                        <div className="justify-start items-start gap-2 flex">
                            <div className="px-5 py-2 rounded-xl justify-start items-center gap-2.5 flex">
                                <div className="w-4 h-4 relative" />
                                <div className="text-zinc-800 text-sm leading-normal cursor-pointer"
                                    onClick={() => navigate('/login')}
                                >
                                    <FontAwesomeIcon icon={faSignIn} /> Sign in / Create Account
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
        );
    }

    function NavBar1() {
        return (
            <nav className="decoration-clone bg-gradient-to-b from-blue-400 to-blue-500">
                <div className="w-full px-4 sm:px-6 lg:px-8">
                    <div className="flex items-center justify-between h-16">
                        <div className="flex flex-grow items-center text-white">
                            <div className="flex-shrink-0 mr-2">
                                <FontAwesomeIcon icon={faFileExport} aria-hidden="true" className="mr-2" />
                                Give a file
                            </div>
                            <div className="hidden md:block flex-grow ">
                                <div className="ml-10 flex flex-grow justify-items-start items-baseline space-x-1 rounded-xl p-1">
                                    <NavLinks />
                                    <div className="flex-grow flex justify-end space-x-4">
                                        {/* <button onClick={() => navigate('/about')}>About</button> */}
                                        {/* <button onClick={() => navigate('/pricing')}>Pricing</button> */}

                                        {!user &&
                                            <button onClick={() => navigate('/login')}>
                                                <FontAwesomeIcon icon={faArrowRightToArc} /> Sign In
                                            </button>
                                        }
                                        {user &&
                                            <UserMenu />
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
        );
    }

    return (
        <>
            <div>
                <NavBar />
                <div>
                    <Outlet />
                </div>
            </div>
            <Footer />
        </>
    );
}

