import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShareAlt } from '@fortawesome/pro-light-svg-icons';

function About() {
    return (
        <div className="container mx-auto sm:px-6 lg:px-8">
            <article className="prose prose-sm sm:prose lg:prose-lg xl:prose-xl" style={{"maxWidth": "100ch"}}>
                <section>
                    <p className="text-left">
                        <i className="fal fa-8x fa-share-alt" aria-hidden="true" style={{"marginRight": "25px"}}></i>
                        <FontAwesomeIcon icon={faShareAlt} size="8x" style={{"margin-right": "25px"}} />
                        The
                        <span className="text-green-500"> secure</span>,
                        <span className="text-yellow-500"> easy</span> and
                        <span className="text-blue-500"> convenient</span> way to share files.
                    </p>
                </section>
                <section className="mt-5">
                    <h2>Usage</h2>
                    <p>
                    You can use this service to share a file with other people. Each shared file
                    has an expiration date, after which it will be deleted and
                    the link will no longer work. 
                    </p>
                    <p>
                    The following <span className="text-green-500">options</span> are available:
                    </p>
                    <dl className="ml-4">
                        <dt className="font-bold">Expires in</dt>
                        <dd className="ml-2">Expiration date, after which the file will no longer be available and will be deleted from giveafile.com</dd>
                        <dt className="font-bold">Pack it</dt>
                        <dd className="ml-2">Allows for the file to be zipped (i.e. compressed) to reduce its size, as well as to password protect it.</dd>
                        <dt className="font-bold">Secure it</dt>
                        <dd className="ml-2">If the file is packed, then you have the option to password protected. This also means that the person
                            receiving the file will need to know the password in order to unpack it. It is best practice to send
                            the password in a separate message and not together in the same message with the file access link.
                        </dd>
                    </dl>
                </section>
                <section className="mt-7">
                    <h2>Terms and Conditions</h2>
                    <p>
                        giveafile.com is a service provided by Work Smarter Computing LLC for sending and receiving files. Neither
                        giveafile.com nor Work Smarter Computing are responsible for the contents of files and no guarantee of
                        any kind is given as to the functionality or usage of this service for any purpose. By using this
                        service, you acknowldege acceptance of these terms and confirm that you are using this 
                        service at your own risk with no guarantees what-so-ever from Work Smarter Computing or giveafile.com.
                        All that giveafile.com does
                        is store files on a temporary basis and provides a mean to access
                        these files to anyone with posession of the access URL.
                    </p>
                </section>
            </article>
        </div>
    );
}

export default About;
