import { useState } from 'react';

export function GAFToggle(props) {
    const [enabled, setEnabled] = useState(props.active || false);

    const handleChange = () => {
        const currentEnabled = !enabled;
        if (props.onClick && typeof(props.onClick) === 'function') {
            props.onClick(currentEnabled);
        }
        setEnabled(prevValue => !prevValue);
    }

    return (
        <label>
            <input type="checkbox" checked={enabled} onChange={handleChange} />
            <span className="text-zinc-500 text-sm font-['Geist Variable'] leading-tight">
                {props.children}
            </span>
        </label>
    );
}

