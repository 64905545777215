import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { Authenticator, useAuthenticator, View } from '@aws-amplify/ui-react';

import { useNavigate, useLocation } from 'react-router';

export function Login() {
    const { route } = useAuthenticator((context) => [context.route]);
    const location = useLocation();
    const navigate = useNavigate();
    const [ searchParams ] = useSearchParams();
    const [ initialState, setInitialState ] = useState('signIn');

    let from = location.state?.from?.pathname || '/private/dashboard';

    useEffect(() => {
        if (searchParams.get('signUp')) {
            setInitialState('signUp');
        }

        if (route === 'authenticated') {
            navigate(from, {
                replace: true,
                state: location.state
            });
        }
    //}, []);
    }, [route]);
    //}, [route, navigate, from]);

    return (
        <View className="auth-wrapper">
            <Authenticator initialState={initialState}></Authenticator>
        </View>
    );
}

