import { useEffect, useState, useCallback, useRef, Suspense } from 'react';
import { post, generateClient } from 'aws-amplify/api';
import { getFileCount } from '../graphql/queries';
import { Spinner } from './Spinner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileUpload, faTrashAlt, faHandPaper, faHand } from '@fortawesome/pro-light-svg-icons';
import { Switch, } from '@headlessui/react';

import ShareLinkImage from '../assets/share-link.svg';
import Confirmation from './Confirmation';
import { fetchUserProfile } from '../services/User';
import { useNavigate } from 'react-router-dom';
import { GiveFileForm } from './GiveFileForm';

const gqlClient = generateClient();

export function GiveFile() {
    const [ isWaiting, setIsWaiting ] = useState(true);
    const [ isNetworkDown, setIsNetworkDown ] = useState(false);
    const [ filelist, setFilelist ] = useState([]);
    const [ showConfirmation, setShowConfirmation ] = useState(false);
    const [ file ] = useState({});
    const [ isUploadDisabled ] = useState(false);
    const [ filePreview, setFilePreview ] = useState(ShareLinkImage);
    const [ zipIt, setZipIt ] = useState('');
    const [ zipPassword, setZipPassword] = useState('');
    const [ expiration, setExpiration ] = useState(3600);
    const [ confirmReceipt, setConfirmReceipt ] = useState(false);
    const [ notifyRecipient, setNotifyRecipient ] = useState(false);
    const [ activeFeatures, setActiveFeatures ] = useState({});
    const [ recipient, setRecipient ] = useState('');
    const [ uploadError, setUploadError ] = useState('');
    const [ presignToken, setPresignToken ] = useState('');
    const [ presignUrl, setPresignUrl ] = useState('');
    const [ uploadCompletePercent, setUploadCompletePercent ] = useState(0);
    const [ maxGives, setMaxGives ] = useState(null);
    const [ currentMonthGiveCount, setCurrentMonthGiveCount ] = useState(null);

    const [ isDragActive, setIsDragActive ] = useState(false);

    const navigate = useNavigate()

    const getRemainingGives = () => {
        return Infinity;
    }

    const countFileGives = async (username) => {
        const today = new Date();
        const month = (today.getMonth()+1).toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping: false});
        const currentMonthYear = today.getFullYear() + month;

        const getCountResponse = await gqlClient.graphql({
            query: getFileCount,
            variables: {
                input: {
                    owner: username,
                    objectName: 'GIVES',
                    period: currentMonthYear
                }
            }
        });

        const getFileCountData = getCountResponse.data.getFileCount;
        const fileCount = getFileCountData? getFileCountData.objectCount: 0;
        //if (fileCount) {
        //    setCurrentMonthGiveCount(fileCount.objectCount || 0);
       // }
        return fileCount;
    };

    const init = useCallback(async () => {
        const userProfile = await fetchUserProfile();
        const limits = userProfile.limits;

        if (limits['maxGives']) {
            setMaxGives(limits['maxGives'])
        } if (limits['notifyRecipient']) {
            activeFeatures['notifyRecipient'] = true;
            setActiveFeatures(activeFeatures);
        }

        // const numFileGives = await countFileGives(userProfile.owner);
        // setCurrentMonthGiveCount(numFileGives);
    }, [setMaxGives, setCurrentMonthGiveCount]);

    useEffect(() => {
        setIsWaiting(true);
        init()
            .catch(error => console.error('loadUserProfile():', error))
            .finally(() => setIsWaiting(false));
        //countFileGives()
        //    .catch(error => console.error('countFileGives():', error));
    }, [init]);

    function formatBytesForDisplay(bytes, decimals) {
        if(bytes === 0) {
            return "0 Byte";
        }
        var k = 1024;
        var sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB"];
        var i = Math.floor(Math.log(bytes) / Math.log(k));

        return parseFloat((bytes / Math.pow(k, i)).toFixed(decimals)) + " " + sizes[i];
    }

    function onFileChange() {

    }

    function onRemoveFile(file) {
        const i = filelist.indexOf(file)
        filelist.splice(i, 1);
        setFilePreview(ShareLinkImage);

        URL.revokeObjectURL(filePreview);
    }

    function onDrop(event) {
        event.preventDefault();
        event.stopPropagation();

        const droppedFiles = event.dataTransfer.files;
        setFilelist([...droppedFiles]);

        setIsDragActive(false);
        if (droppedFiles.length > 0) {
             const firstFile = droppedFiles[0];
            setFilePreview(URL.createObjectURL(firstFile))
        }
    }

    function onDrag(event) {
        event.preventDefault();
        event.stopPropagation();

        if (event.type === "dragenter" || event.type === "dragover") {
            setIsDragActive(true);
        } else if (event.type === "dragleave") {
            setIsDragActive(false);
        }

    }

    async function upload(event) {
        event.preventDefault();

        setIsWaiting(true);
        setIsNetworkDown(false);
        setUploadError('');

        if (filelist.length == 0) {
            setUploadError('No files selected to upload');
            setIsWaiting(false);
            return;
        }

        try {
            const form = event.target;
            const formData = new FormData(form);
            const formJson = Object.fromEntries(formData.entries());
            const file = filelist[0];

            const response = await post('giveafileApi', '/token', {
                body: {
                    ttl: formJson.ttl,
                    fileName: file.name,
                    fileSize: file.size,
                    fileContentType: file.type,
                }
            });
            // const response = await API.post('GAFRestAPI', '/file', {
            //     body: {
            //         ttl: formJson.ttl,
            //         recipients: formJson.recipient,
            //         confirmReceipt: confirmReceipt? 'true': 'false',
            //         zipIt: formJson.zipIt === 'yes'? 'true': 'false',
            //         zipPassword: formJson.zipPassword || '',
            //         notifyRecipient: notifyRecipient? 'true': 'false',
            //         filename: file.name,
            //         contentType: file.type,
            //         fileSize: file.size,
            //     }
            // });
            //console.log('response', response);
            const token = response.token;
            const presignPost = response.presignPost;

            const uploadForm = new FormData();
            uploadForm.append('Content-Type', file.type);
            Object.entries(presignPost.fields).forEach(([field, value]) => {
                uploadForm.append(field, value);
            });
            uploadForm.append("file", file);
            const uploadResponse = await fetch(presignPost.url, {
                method: 'POST',
                body: uploadForm
            });

            const responseText = await uploadResponse.text();
            console.log('upload response:', ":", responseText);
            if (!uploadResponse.ok) {
                const responseXml = new DOMParser().parseFromString(responseText, "text/xml");
                const error = responseXml.getElementsByTagName('Error')[0];
                const code = error.getElementsByTagName('Code')[0].innerHTML;
                if (code === 'EntityTooLarge') {
                    const maxSize = error.getElementsByTagName('MaxSizeAllowed')[0].innerHTML;
                    throw new Error(`File is too big. Your plan allows for a maximum file size of ${(maxSize/1024/1024).toFixed(2)} MB.`);
                }
                throw new Error(`Ooops, an unexpected error occured. [Code: ${code}]`);
            }

            setExpiration(formJson.ttl);


            /*
            const expireTime = Date.now() + (formJson.ttl*1000);
            const expireTimestamp = new Date();
            expireTimestamp.setTime(expireTime);

            const file = filelist[0];
            const token = crypto.randomUUID();
            await Storage.put(encodeURI(file.name), file, {
                level: 'private',
                contentType: file.type,
                expires: expireTimestamp,
                metadata: {
                    expireTimestamp: expireTimestamp.toUTCString(),
                    recipients: formJson.recipient,
                    confirmReceipt: formJson.confirmReceipt === 'on'? 'true': 'false',
                    zipIt: formJson.zipIt === 'yes'? 'true': 'false',
                    zipPassword: formJson.zipPassword || '',
                    owner: user.username,
                    token: token
                },
                progressCallback(progress) {
                    setUploadCompletePercent((progress.loaded/progress.total) * 100);
                }
            })*/
            setPresignUrl(`https://www.giveafile.com/${token}`);
            setShowConfirmation(true);
        } catch(e) {
            console.error('Catch:', e);
            if (e.response) {
                const responseData = e.response.data;
                const message = responseData['UserMessage'];
                if (message) {
                    setUploadError(message);
                } else {
                    setUploadError(`${e}`);
                }
            } else {
                setUploadError('An unexpected error occured. Please try again');
            }
        } finally {
            setIsWaiting(false);
        }
        /*.then(result => {
            
            const s3ObjectKey = result.key;

            const giveDetails = {
                token: crypto.randomUUID(),
                expireTimestamp: expireTimestamp.toISOString(),
                accessCount: 0,
                s3Key: s3ObjectKey,
                recipients: [formJson.recipient],
                confirmReceipt: formJson.confirmReceipt,
            };
            console.log('Give Details: ', giveDetails);
            API.graphql(graphqlOperation(mutations.createFiles, { input: giveDetails }))
                .catch(e => {
                    console.log('Error updating db', e);
                    setUploadError('An unexpected error occured. Please try again');
                });

            setShowConfirmation(true);
        })
        .catch(e => {
            console.log('Error updating db', e);
            setUploadError('An unexpected error occured. Please try again');
        }).finally(() => {
            console.log('finally');
            setIsWaiting(false);
        });*/

        /*
        let formData1 = new FormData();
        formData1.append('expiresInSeconds', expiration);
        formData1.append('zip_it', zipIt);
        formData1.append('zip_password', zipPassword);

        Array.from(filelist).forEach(file => {
            formData1.append('file', file);
        });

        axios.post('https://api.giveafile.com/files', formData1, {})
        .then((res) => {
            console.log('Res', res);
            if (res.status == 200) {
                const token = res.data.token;
                setPresignToken(token);
                setPresignUrl(`https://www.giveafile.com/#/get?token=${token}`);
                setShowConfirmation(true);
            } else {
                setUploadError (res);
            }
        }).catch((err) => {
            console.log('Err', err)
            if (!err.status) {
                setUploadError('A network error occured. Please check your internet connection.');
                setIsNetworkDown(true);
            } else {
                setUploadError(err);
            }
        }).finally(() => {
            console.log('finally');
            setIsWaiting(false);
        });
        */

    }

    function FilePreview() {
        let preview = <></>;

        //if (filelist.length == 0) {
        //    preview =  <img className="rounded-lg object-fill" src={ShareLinkImage} alt="" width="384" height="384" style={{'filter': 'opacity(0.2) grayscale(1)'}}/>;
        //} else {
        if (filelist.length > 0) {
            const firstFile = filelist[0];
            const fileType = firstFile['type'];
            const isImage = fileType.split('/')[0] === 'image';

            if (isImage) {
                preview = <div className="flex-none border border-gray-300 p-3 h-full"><img className="rounded-lg object-fill" src={filePreview} alt="" width="384" height="384" /></div>
            } else {
                preview = <div className="flex-none border border-gray-300 p-3 h-full"><iframe content={`${fileType};charset=UTF-8`} className="rounded-none object-contain h-full" src={filePreview} alt="" width="384" /></div>
            }
        }

        return (
            preview
        );
    }

    function EmptyDropbox() {
        return (
            <div className="grid h-48 place-items-center">
                <input type="file" multiple name="fields[assetsFieldHandle][]" id="assetsFieldHandle" 
                      className="w-px h-px opacity-0 overflow-hidden absolute" onChange={onFileChange} useref={file} accept=".pdf,.jpg,.jpeg,.png" />
                <label htmlFor="assetsFieldHandle" className="cursor-pointer text-center">
                    <FontAwesomeIcon icon={faFileUpload} size="4x" className="text-gray-400" /> <br />
                    Drag a file over this box
                    or <u>click here</u> to choose one.
                </label>
            </div>
        );
    }

    function DropboxFiles() {
        return (
            <div className="flex flex-col justify-between h-full p-3">
                <div className="grid grid-cols-4">
                    {filelist.length && 
                        <>
                            <div className="col-span-3">
                                <small className="text-sm">File name: </small>
                                <span className="font-bold text-xl">{filelist[0].name}</span>
                            </div>
                            <button onClick={() => onRemoveFile(file)} className="bg-red-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded w-12 justify-self-end" type="button" title="Remove file">
                                <FontAwesomeIcon icon={faTrashAlt} aria-hidden="true" />
                            </button>
                        </>
                    }
                </div>
                <div className="flex justify-center">
                    <div className="mx-2">
                        <div className="rounded overflow-hidden shadow-lg bg-blue-200 w-40">
                            <div className="px-6 pt-4 pb-2 text-center">Size</div>
                            <div className="font-bold text-xl px-6 py-4 text-gray-700 bg-gray-300 text-center rounded">
                                {filelist.length > 0 &&
                                    <span className="text-base">{formatBytesForDisplay(filelist[0].size, 2)}</span>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="mx-2 grow">
                        <div className="rounded overflow-hidden shadow-lg bg-blue-200">
                            <div className="px-6 pt-4 pb-2 text-center">File type</div>
                            <div className="font-bold text-xl px-6 py-4 text-gray-700 bg-gray-300 text-center rounded">
                                {filelist.length > 0 &&
                                    <span className="text-base">{filelist[0].type}</span>
                                }
                            </div>
                        </div>
                    </div>
                </div>
{/*            <dl className="p-4">
                {filelist.map((file) => 
                    <div key={file}>
                        <div className="sm:grid sm:grid-cols-4 sm:gap-4 sm:px-6">
                            <dt className="text-sm font-medium text-gray-500">Name:</dt>
                            <dd className="font-semibold sm:col-span-3">{ file.name }</dd>
                        </div>
                        <div className="sm:grid sm:grid-cols-4 sm:gap-4 sm:px-6">
                            <dt className="text-sm font-medium text-gray-500">Size:</dt>
                            <dd className="font-semibold sm:col-span-3">{ formatBytesForDisplay(file.size, 2) }</dd>
                        </div>
                        <div className="sm:grid sm:grid-cols-4 sm:gap-4 sm:px-6">
                            <dt className="text-sm font-medium text-gray-500">Action:</dt>
                            <dd className="font-semibold sm:col-span-3">
                                <button className="ml-2 text-red-500" type="button" onClick={() => onRemoveFile(file)} title="Remove file">
                                    <FontAwesomeIcon icon={faTrashAlt} aria-hidden="true" />
                                </button>
                            </dd>
                        </div>
                    </div>
                )}
            </dl>
*/}
            </div>
        );
    }

    return (
        <>
            { isWaiting && <><Spinner />Completed: {uploadCompletePercent}%</> }
            <div className="w-full sm:px-6 lg:px-8 flex flex-col gap-10 items-center">
                <GiveFileForm />
            </div>
        </>
    );
    return (
        <Suspense fallback={<Spinner />}>
            <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
                <div className="px-4 sm:px-0">
                    {isWaiting && (<><Spinner />Completed: {uploadCompletePercent}%</>)}
                </div>
                {!isWaiting && getRemainingGives() === 0 &&
                    <div className="w-full h-full fixed flex top-0 left-0 z-50 bg-gray-500 bg-opacity-50">
                        <div className="m-auto">
                            <div className="mb-5 text-center">
                                <div className="text-red-700 text-6xl mb-5"><FontAwesomeIcon icon={faHand} size="3x" /></div>
                                <div className="bg-yellow-200 p-5 shadow-lg">
                                    You have reached your monthly quota of {maxGives} gives.
                                    Check out our other plans with higher quota limits.
                                </div>
                            </div>
                            <div className="text-center">
                                <button
                                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                                    onClick={() => navigate("/pricing")}
                                >
                                    Upgrade
                                </button>
                                <button
                                    className="bg-neutral-50 underline hover:bg-blue-700 py-2 px-4 rounded mx-4"
                                    onClick={() => navigate("/dashboard")}
                                >
                                    Home
                                </button>
                            </div>
                        </div>
                    </div>
                }
                {showConfirmation &&
                    <div className="flex flex-col content-center"> 
                        <Confirmation presignUrl={presignUrl} ttlSeconds={expiration}></Confirmation>
                        <div className="flex flex-row justify-center">
                            <button onClick={() => {setShowConfirmation(false); setFilelist([]); navigate("/give")}} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded m-5">Give another</button>
                            <button onClick={() => {setShowConfirmation(false); setFilelist([]); navigate("/dashboard")}} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded m-5">Go home</button>
                        </div>
                    </div>
                }
                {!showConfirmation && 
                    <div className="p-6 pb-3 flex content-center">
                        {filePreview && 
                            <div className="flex flex-col mr-3">
                                <FilePreview />
                            </div>
                        }
                        <div className="m-auto">
                            The <strong className="text-green-500">secure</strong> way to give a file in 
                            <span className="text-lg"> 4</span> <span className="text-yellow-500">easy</span> steps:
                            <ol className="list-decimal text-left mt-3 ml-12">
                                <li><strong>Choose</strong> a file</li>
                                <li><strong>Set</strong> some options</li>
                                <li><strong>Upload</strong></li>
                                <li><strong>Give</strong> the link to someone
                                    (i.e. include it in an email, a text message, or anywhere else)</li>
                            </ol>
                            <hr className="m-4"/>
                            <form method="post" onSubmit={upload} encType="multipart/form-data">
                                <div className={`m-6 mb-3 p-0 bg-gray-100 border border-gray-300 rounded-lg ${isDragActive? 'bg-green-300': 'bg-gray-100'}`}
                                        onDragEnter={onDrag} onDragLeave={onDrag} onDragOver={onDrag} onDrop={onDrop}>
                                    <figure className="grid grid-cols-1 rounded-xl p-8 md:p-0 h-60">
                                        <div className="w-full col-span-2 pt-0 text-center md:text-left space-y-4">
                                            {filelist.length === 0
                                                ? <EmptyDropbox />
                                                : <DropboxFiles />
                                            }
                                        </div>
                                    </figure>
                                </div>
                                <p className="text-center mb-3">
                                    Set some options:
                                </p>

                                {/* Upload Options */}
                                <div className="flex flex-col flex-wrap mx-3 mb-6">
                                    <div className="w-full px-3 mb-6 md:mb-0">
                                        <label htmlFor="recipientInput" className="block tracking-wide text-gray-700 mb-2">
                                            Give to
                                        </label>
                                        <input id="recipientInput" name="recipient" type="email"
                                            className="appearance-none block w-full bg-gray-200 text-gray-700 border border-red-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                                            placeholder="Recipient email" />
                                        <p className="text-gray-600 text-xs italic">Leaving this blank will allow anyone with the link to get the file until the expiration time</p>
                                    </div>
                                    <Switch.Group as="div" className="mt-6 ">
                                        <div className="flex items-center mb-6 px-3">
                                            <Switch.Label className="mr-4 text-gray-700">Receipt Confirmation</Switch.Label>
                                            <Switch
                                                checked={confirmReceipt}
                                                onChange={setConfirmReceipt}
                                                className={`${confirmReceipt ? 'bg-blue-600' : 'bg-gray-200'}
                                                  relative inline-flex h-[25px] w-[37px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
                                              >
                                                  <span className="sr-only">Delivery Confirmation</span>
                                                  <span
                                                      aria-hidden="true"
                                                      className={`${confirmReceipt ? 'translate-x-4' : 'translate-x-0'}
                                                        pointer-events-none inline-block h-[20px] w-[17px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
                                                  />
                                            </Switch>
                                        </div>
                                        { activeFeatures['notifyRecipient'] && 
                                            <div className="flex items-center mb-6 px-3">
                                                <Switch.Label className="mr-4 text-gray-700">Notify Recipient</Switch.Label>
                                                <Switch
                                                    checked={notifyRecipient}
                                                    onChange={setNotifyRecipient}
                                                    className={`${notifyRecipient ? 'bg-blue-600' : 'bg-gray-200'}
                                                      relative inline-flex h-[25px] w-[37px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
                                                  >
                                                      <span className="sr-only">Notify Recipient</span>
                                                      <span
                                                          aria-hidden="true"
                                                          className={`${notifyRecipient ? 'translate-x-4' : 'translate-x-0'}
                                                            pointer-events-none inline-block h-[20px] w-[17px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
                                                      />
                                                </Switch>
                                            </div>
                                        }
                                    </Switch.Group>
                                    {/*<div className="md:flex md:items-center content-start mb-6">
                                        <label className="md:w-2/3 block text-gray-500 font-bold">
                                            <input name="confirmReceipt" defaultChecked={false} className="mr-2 leading-tight" type="checkbox" />
                                            <span className="text-sm">
                                                Request a Delivery Receipt 
                                            </span>
                                        </label>
                                    </div>*/}
                                    <div className="w-full px-3">
                                        <label className="block tracking-wide text-gray-700 mb-2">
                                            <span className="text-gray-700">Expires in:</span>
                                            <select id="ttl" name="ttl" defaultValue={expiration} className="form-select block w-full mt-1">
                                                <option value="3600">1 hour</option>
                                                <option value="7200">2 hours</option>
                                                <option value="18000">5 hours</option>
                                                <option value="86400">1 day (24 hours)</option>
                                                <option value="172800">2 days (48 hours)</option>
                                            </select>
                                        </label>
                                    </div>
                                    <div>
                                        <div>&nbsp;</div>
                                        <button id="upload-btn" type="submit" disabled={isUploadDisabled} className="bg-green-500 hover:bg-blue-700 text-white font-bold py-2 px-4 ml-1 mt-1 block w-full">Upload</button>
                                    </div>
                                </div>
                                <div className="flex flex-col sm:flex-row justify-items-center max-w">
                                    {/*<label className="block tracking-wide text-gray-700 mb-2">
                                        <span className="text-gray-700">Expires in:</span>
                                        <select id="ttl" name="ttl" defaultValue={expiration} className="form-select block w-full mt-1">
                                            <option value="3600">1 hour</option>
                                            <option value="7200">2 hours</option>
                                            <option value="18000">5 hours</option>
                                            <option value="86400">1 day (24 hours)</option>
                                            <option value="172800">2 days (48 hours)</option>
                                        </select>
                                    </label>
                                    <label>
                                        <span className="text-gray-700">Pack it?</span>
                                        <select name="zipIt" className="form-select block w-full mt-1"
                                            onChange={e => setZipIt(e.target.value)}>
                                            <option value="">Pick one</option>
                                            <option value="yes">Yes</option>
                                            <option value="no">No</option>
                                        </select>
                                    </label>
                                    <label className="flex-grow">
                                        <span className="text-gray-700">Secure it:</span>
                                        <input name="zipPassword" type="password" className="form-input mt-1 block w-full"
                                                placeholder="with password"
                                                disabled={zipIt!='yes'}/>
                                    </label>
                                    <div>
                                        <div>&nbsp;</div>
                                        <button id="upload-btn" type="submit" disabled={isUploadDisabled} className="bg-green-500 hover:bg-blue-700 text-white font-bold py-2 px-4 ml-1 mt-1 block w-full">Upload</button>
                                    </div>*/}
                                </div>
                                <div className="text-red-500 text-center mt-3">
                                    {uploadError && <><FontAwesomeIcon icon={faHandPaper} size="2x" /> {uploadError}</>}
                                </div>
                            </form>
                        </div>
                    </div>
                }
            </div>
        </Suspense>
    );
}

